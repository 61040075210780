import styled from "styled-components";

type Props = {
  images: string[];
};

const Gallery = ({ images }: Props) => {
  return (
    <GridGallery>
      {images?.map((image) => (
        <GalleryItem key={image}>
          <img src={image} alt="#" />
        </GalleryItem>
      ))}
    </GridGallery>
  );
};

export default Gallery;

const GridGallery = styled.div`
  display: grid;
  grid-auto-rows: 400px;
  grid-gap: 1rem;
  grid-auto-flow: row dense;

  @media all and (min-width: 320px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media all and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media all and (min-width: 1024px) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const GalleryItem = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:nth-child(11n + 1) {
    grid-column: span 1;
  }

  &:nth-child(11n + 4) {
    grid-column: span 2;
    grid-row: span 1;
  }

  &:nth-child(11n + 6) {
    grid-column: span 3;
    grid-row: span 1;
  }

  &:nth-child(11n + 7) {
    grid-column: span 1;
    grid-row: span 2;
  }

  &:nth-child(11n + 8) {
    grid-column: span 2;
    grid-row: span 2;
  }

  &:nth-child(11n + 9) {
    grid-row: span 3;
  }
`;
