import { configureStore } from '@reduxjs/toolkit';
import loading from './loading';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
 
const persistConfig = {
  key: 'loading',
  whitelist: ['actualOption'],
  storage,
}

export const store = configureStore({
  reducer: {
    loading: persistReducer(persistConfig, loading)
  },
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch