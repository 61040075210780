import styled from "styled-components";
export const PageContainer = styled.div<{ dark?: boolean }>`
  width: 100%;
  margin: auto;
  height: 100%;
  padding: 64px 10% 24px 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme, dark }) =>
    dark ? theme.secondary.color : theme.light.color};
  color: ${({ theme, dark }) =>
    dark ? theme.secondary.contrast : theme.light.contrast};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 32px 16px;
  }
`;
