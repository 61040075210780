import { useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { INITIAL_LOADING_TIME, MENU_OPTIONS } from "../../utils/constants";
import LogoIcon from "./../../assets/logo.svg";
import Menu from "../Menu/Menu";
import {
  NavbarContainer,
  MenuContainer,
  MenuOption,
  MenuIconContainer,
  Bar,
  Logo,
} from "./styles";
import { NavbarProps, MenuOptionEnum } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/loading";
import { delayMs } from "../../utils/delay";
import { RootState } from "../../redux/store";

const Navbar = ({ animated = true, className, onOptionClick }: NavbarProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { actualOption } = useSelector((state: RootState) => state.loading);
  const [showMenu, toggleMenu] = useState(false);

  const handleOptionClicked = useCallback(
    async (option: MenuOptionEnum) => {
      const isNewsDetail = actualOption === MenuOptionEnum.News && pathname !== '/noticias';
      if (actualOption !== option || isNewsDetail) {
        dispatch(actions.setLoading(true));
        await delayMs(INITIAL_LOADING_TIME + 100);
        if (onOptionClick) onOptionClick(option);
        toggleMenu(false);
        dispatch(actions.setActualOption(option));
        history.push(`/${option}`);
      }
      
    },
    [actualOption, dispatch, history, onOptionClick, pathname]
  );

  const renderOptions = useCallback(() => {
    return MENU_OPTIONS.filter(
      (option) => option.id !== MenuOptionEnum.Admission
    ).map((option) => (
      <MenuOption
        key={option.id}
        active={actualOption === option.id}
        onClick={() => handleOptionClicked(option.id)}
      >
        {option.label}
      </MenuOption>
    ));
  }, [actualOption, handleOptionClicked]);

  return (
    <>
      <NavbarContainer className={className} animated={animated}>
        <Logo
          src={LogoIcon}
          alt="logo"
          onClick={() => handleOptionClicked(MenuOptionEnum.Home)}
        />
        <MenuContainer>{renderOptions()}</MenuContainer>
        <MenuContainer>
          <MenuOption
            key={MenuOptionEnum.Admission}
            active={actualOption === MenuOptionEnum.Admission}
            onClick={() => handleOptionClicked(MenuOptionEnum.Admission)}
          >
            Inscripción
          </MenuOption>
        </MenuContainer>
        <MenuIconContainer onClick={() => toggleMenu(true)}>
          <Bar />
          <Bar />
          <Bar />
        </MenuIconContainer>
      </NavbarContainer>
      <Menu
        show={showMenu}
        onClose={() => toggleMenu(false)}
        onOptionClick={handleOptionClicked}
      />
    </>
  );
};

export default Navbar;
