import styled from "styled-components";

interface Props {
  placeholder: string;
  name?: string;
  type?: string;
  value?: string | number;
  errorText?: string;
  isError?: boolean;
  style?: React.CSSProperties;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

const Input = (props: Props) => {
  const {
    style,
    type = "text",
    errorText = "",
    isError = false,
    ...others
  } = props;
  return (
    <Container>
      <StyledInput
        isError={isError}
        type={type}
        autoComplete="off"
        {...others}
      />
      <HelperText isError={isError}>{isError ? errorText : ""}</HelperText>
    </Container>
  );
};

export default Input;

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const StyledInput = styled.input<{ isError: boolean }>`
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-color: transparent;
  border-color: ${({ isError }) => (isError ? "red" : "#777")};
  color: ${({ theme }) => theme.secondary.contrast};
  outline: none;
  margin: 0 8px;
  min-width: 200px;
  font-size: 18px;
  text-align: center;

  &::placeholder {
    font-size: 18px;
    text-align: center;
    color: #aaa;
  }
  &:-ms-input-placeholder {
    font-size: 18px;
    text-align: center;
    color: #aaa;
  }
  &::-ms-input-placeholder {
    font-size: 18px;
    text-align: center;
    color: #aaa;
  }
`;

const HelperText = styled.p<{ isError: boolean }>`
  margin: 2px 4px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: ${({ theme, isError }) => (isError ? "red" : "#888")};
`;
