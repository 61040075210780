import { SlideType } from "../../../components/Carrousel/Carrousel";
import EnglishImage from "../../../assets/images/ingles_1.webp";
import SportsImage from "../../../assets/images/_DUA5009.webp";
import Pool from "../../../assets/images/kids_swimming.webp";
import GreenImage from "../../../assets/images/_DUA6493.webp";
import CharityImage from "../../../assets/images/solidario1.webp";
import Cambridge from "../../../assets/images/cambridge.png";

export const slides: SlideType[] = [
  {
    title: "Intensificación en Inglés",
    description:
      "Adheridos al Programa de Escuelas con Intensificación de Inglés del Ministerio de Educación de CABA, nos caracterizamos por una destacada formación en el idioma, preparando a nuestros alumnos para certificarse en las distintas instancias.",
    imageUrl: EnglishImage,
    redirectUrl: "/ingles",
    logo: Cambridge
  },
  {
    title: "Departamento Ecológico",
    description:
      "Buscamos concientizar y formar nuevos hábitos en toda nuestra comunidad educativa a través de este proyecto que atraviesa toda nuestra estructura.",
    imageUrl: GreenImage,
    redirectUrl: "/escuela-verde",
  },
  {
    title: "Campo de deportes",
    description:
      "Trasladamos a nuestros alumnos al Club Cirse para la preparación física y práctica deportiva.",
    imageUrl: SportsImage,
    redirectUrl:
      "https://www.google.com/maps/dir//club+cirse/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x95bcb703b5eb5e53:0x76a78fa442567bde?sa=X&ved=2ahUKEwjQiqelq7LzAhXAr5UCHdOoCWYQ9Rd6BAgJEAQ",
    buttonText: "Ver ubicación",
    external: true,
  },
  {
    title: "Natatorio",
    description:
      "Contamos con un natatorio propio para los más pequeños, y participación en torneos y competencias.",
    imageUrl: Pool,
    //redirectUrl: "/primaria",
  },

  {
    title: "Proyectos Solidarios",
    description:
      "Abordando temas importantes dentro de nuestra comunidad, llevamos a cabo distintos proyectos con la participación de todos los niveles.",
    imageUrl: CharityImage,
    buttonText: "Ver fotos",
    redirectUrl: "/proyectos-solidarios",
  },
];
