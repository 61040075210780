import { Col, Grid, Row } from "@jcbruno96/react-ui-library";
import styled from "styled-components";
import Baoc from "../../../assets/images/baoc.png";
import Cambridge from "../../../assets/images/cambridge.png";
import { Highlight } from "../../../components/Highlight/Highlight";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { Title } from "../../../components/Title/Title";

const Header = () => {
  return (
    <PageContainer dark style={{ minHeight: '100vh'}}>
      <br />
      <br />
      <br />
      <Grid>
        <Row>
          <Col xs={12} style={{ flexDirection: "column" }}>
            <Title style={{ textAlign: "center", paddingTop: 16 }}>
              Proyecto Institucional de <Highlight>Inglés</Highlight>
            </Title>
            <br />
            <br />
            <Description>
              El aprendizaje de una lengua extranjera constituye un aspecto
              fundamental en la formación de una persona, pues le permite
              conocer la existencia de otras culturas, comprender otras
              realidades, y comunicarse en otros idiomas, ampliando así sus
              horizontes posibles.
            </Description>
            <Description>
              <>
                El Instituto Educacional Duayen se caracterizó por una destacada
                formación en idioma inglés desde sus inicios, y a partir del año
                2019, ha adherido al{" "}
                <b>
                  <i>Programa de Escuelas con Intensificación de Inglés</i>
                </b>
                , aprobado por el Ministerio de Educación de la Ciudad de Buenos
                Aires. Si bien la enseñanza de la lengua extranjera culmina en
                5º año de la Escuela Media, para nuestros estudiantes será una
                herramienta fundamental en su continuo desarrollo académico y
                profesional.
              </>
            </Description>
            <Description>
              Nuestra propuesta pedagógica prioriza ante todo desarrollar
              valores de responsabilidad, respeto, compromiso, esfuerzo y
              dedicación, por lo que nuestros objetivos de enseñanza trascienden
              los resultados que puedan obtener nuestros estudiantes en los
              exámenes internacionales o los concursos o certámenes en los que
              podamos participar. Buscamos ante todo fomentar en ellos el
              pensamiento crítico y contribuir al desarrollo integral del
              alumnado estimulando y desafiando sus propias habilidades.
            </Description>
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{ justifyContent: "flex-end" }}>
            <a
              href="https://buenosairesopencentre.com/"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={Baoc} alt="BAOC Logo" />
            </a>
            <a
              href="https://www.cambridgeenglish.org/latinamerica/"
              target="_blank"
              rel="noreferrer"
            >
              <Image src={Cambridge} alt="Cambridge Logo" />
            </a>
          </Col>
        </Row>
      </Grid>
      <br />
      <br />
    </PageContainer>
  );
};

export default Header;

const Image = styled.img`
  width: 120px;
  height: auto;
  object-fit: contain;
  margin: 24px;
`;

const Description = styled.p`
  position: relative;
  font-size: 1.1rem;
  max-width: 1200px;
  width: 100%;
  cursor: default;
  text-align: left;
  letter-spacing: 1px;
  margin-bottom: 2rem;
  text-align: justify;

  @media (max-width: 768px) {
    font-size: 1.05rem;
  }

  @media (max-width: 576px) {
    font-size: 0.95rem;
  }
`;
