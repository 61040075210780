export enum NewsCategory {
  All = "",
  Kindergarten = "inicial",
  Primary = "primaria",
  Secondary = "secundaria",
  English = "ingles",
}

export interface NewsFilter {
  category?: NewsCategory;
  limit?: number;
}

export interface News {
  id: number;
  title: string;
  content: string;
  preview_text: string;
  date: string;
  show_note: boolean;
  slug: string;
  created_at: string;
  updated_at: string;
  published_at: string;
  section: string | null;
  news_files?: NewsFile[];
}

export interface NewsFile {
  related_type: string;
  field: string;
  file_id: number;
  related_id: number;
  files: NewsImage;
}

export interface NewsImage {
  id: number;
  name: string;
  url: string;
}
