import { Button } from "../Button/Button";
import {
  Container,
  Description,
  Image,
  Overlay,
  StyledReveal,
  Title,
} from "./styles";
import { LevelHeaderProps } from "./types";

const LevelHeader = ({
  title,
  description,
  imageUrl,
  onClick,
}: LevelHeaderProps) => {

  return (
    <Container className="level-header">
      <Image imageUrl={imageUrl} />
      <StyledReveal delay={200}>
        <Title>{title}</Title>
      </StyledReveal>
      <StyledReveal delay={350}>
        <Description>{description}</Description>
      </StyledReveal>
      {onClick && (
        <StyledReveal delay={500}>
          <Button inverted onClick={onClick}>
            Inscribirme
          </Button>
        </StyledReveal>
      )}
      <Overlay />
    </Container>
  );
};

export default LevelHeader;
