import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { News } from "../../interfaces/news";
import { actions } from "../../redux/loading";
import { getNewsDetail as getNewsDetailApi } from "../../services/news";
import { delayMs } from "../../utils/delay";
import { onImagesLoaded } from "../../utils/images";

export const useNewsDetail = () => {
  const { id = "" } = useParams<{ id: string }>();
  const [news, setNews] = useState<News | null>(null);
  const dispatch = useDispatch();

  const getNewsDetail = useCallback(async () => {
    try {
      const newsId = Number(id);
      if (!isNaN(newsId)) {
        const { data } = await getNewsDetailApi(newsId);
        setNews(data.result || null);
        await delayMs(500);
        const id = "#news-detail-container";
        const images = document.querySelector(id) as Element;
        if (images) await onImagesLoaded(images);
        await delayMs(500);
      }
    } catch (err) {
      console.log("News detail error: ", err);
    } finally {
      dispatch(actions.setLoading(false));
    }
  }, [dispatch, id]);

  useEffect(() => {
    console.log("News detail page");
    getNewsDetail();
  }, [id, getNewsDetail]);

  return {
    news,
  };
};
