import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { News, NewsCategory } from "../../interfaces/news";
import { actions } from "../../redux/loading";
import { getNews as getNewsApi } from "../../services/news";

export const useNews = () => {
  const [news, setNews] = useState<News[]>([]);
  const { section = "" } = useParams<{ section: string }>();
  const [category, setCategory] = useState<NewsCategory | null>(null);
  const dispatch = useDispatch();

  const onChangeCategory = (cat: NewsCategory) => {
    if (category === cat) setCategory(NewsCategory.All);
    else setCategory(cat);
  };

  const getNews = useCallback(
    async (category: NewsCategory | undefined) => {
      try {
        const { data } = await getNewsApi({ category });
        setNews(data?.result || []);
      } catch (err) {
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getNews(category || undefined);
  }, [getNews, category]);

  useEffect(() => {
    if (section === "ingles") {
      setCategory(NewsCategory.English);
    } else if (section === "primaria") {
      setCategory(NewsCategory.Primary);
    } else if (section === "secundaria") {
      setCategory(NewsCategory.Secondary);
    } else if (section === "inicial") {
      setCategory(NewsCategory.Kindergarten);
    } else {
      setCategory(NewsCategory.All);
    }
  }, [section]);

  return {
    category,
    news,
    onChangeCategory,
  };
};
