import { useState, useEffect } from "react";
import {
  LoadingContainer,
  Loader,
  LOADING_FADE_OUT_TIME,
  Spinner,
} from "./styles";
import { LoadingProps } from "./types";

const Loading = ({ show }: LoadingProps) => {
  const [hide, setHide] = useState(false);
  const [spinner, showSpinner] = useState(false);
  const [background, showBackground] = useState(true);
  useEffect(() => {
    if (show) {
      setHide(false);
      setTimeout(() => {
        showSpinner(true);
      }, LOADING_FADE_OUT_TIME);
    } else {
      showBackground(false);
      showSpinner(false);
      setTimeout(() => {
        setHide(true);
      }, LOADING_FADE_OUT_TIME);
    }
  }, [show]);

  return (
    <LoadingContainer hide={!show && hide} background={background}>
      <Loader show={show} />
      {spinner && <Spinner />}
    </LoadingContainer>
  );
};

export default Loading;
