import SecondaryPhoto from "../../../assets/images/_DUA5002.webp";
import KindergartenPhoto from "../../../assets/images/inicial_card.webp";
import PrimaryPhoto from "../../../assets/images/_DUA6674.webp";
import { LevelType } from "./types";

export const DUAYEN_LEVELS: LevelType[] = [
  {
    title: 'Nivel Inicial',
    description: '"Il Pulcino Ballerino" cuenta con salas de dos, tres, cuatro y cinco años.',
    photo: KindergartenPhoto,
    path: '/inicial'
  },
  {
    title: 'Primaria',
    description: 'Plan de Jornada Completa con Intensificación idiomática (Programa de Escuelas con Intensificación de Inglés)',
    photo: PrimaryPhoto,
    path: '/primaria'
  },
  {
    title: 'Secundaria',
    description: '"Bachiller con orientación en Ciencias sociales y Humanidades" (R.M.321 y 1189/15)',
    photo: SecondaryPhoto,
    path: '/secundaria'
  }
]