import { FileType } from "../../../components/DocumentContainer/types";

export const documents: FileType[] = [
  {
    url: "S_Ficha de datos de Alumno 2023.docx",
    label: "Ficha de datos de Alumno",
  },
  {
    url: "S_Ficha de antecedentes de salud 2023.docx",
    label: "Ficha de antecedentes de salud",
  },
  {
    url: "S_Autorizacion Educacion Fisica 2023.docx",
    label: "Autorización Educación Física",
  },
  { url: "S_Autorizacion anual 2023.docx", label: "Autorización anual" },
];
