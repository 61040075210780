import styled from "styled-components";
import { PageContainer } from "../../components/PageContainer/PageContainer";

export const StyledPageContainer = styled(PageContainer)`
  min-height: 100%;
  height: auto;
`;

export const NewsImage = styled.img`
  width: 100%;
  object-fit: cover;
  max-height: 600px;
  margin-top: 24px;
  margin-bottom: 64px;
`;

export const NewsCategory = styled.h2`
  font-size: 18px;
  font-weight: 800;
  width: 100%;
  text-align: center;
  margin-top: 48px;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 32px;
  }
`;

export const NewsTitle = styled.h1`
  font-size: 56px;
  font-weight: 700;
  width: 100%;
  max-width: 900px;
  text-align: center;
  margin: 8px auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 36px;
  }
`;

export const NewsSubtitle = styled.h1`
  font-size: 24px;
  font-weight: 400;
  width: 100%;
  text-align: center;
`;

export const NewsDate = styled.p`
  margin: 16px 0;
  color: #828893;
  font-size: 16px;
  width: 100%;
  text-align: center;
`;

export const NewsContent = styled.div`
  margin-bottom: 64px;
  
  & p {
    margin: 16px 0;
  }

  & p,
  & p span {
    font-family: "Nunito" !important;
  }

  & img {
    max-width: 100%;
  }
`;
