import styled from "styled-components";
import { PageContainer } from "../PageContainer/PageContainer";
import { colorOpacity } from "@jcbruno96/react-ui-library";

export const StyledPageContainer = styled(PageContainer)`
  min-height: 100px;
  height: fit-content;
  padding-top: 24px;
  padding-bottom: 24px;
  margin: 0;
  background-color: #f1f1f1;
`;

export const CopyrightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    text-align: center;
    padding: 8px 16px;
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const SocialMedia = styled.div`
  min-width: 48px;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => colorOpacity(theme.primary.color, 0.1)};
  transition: background-color 0.3s, color 0.2s;
  cursor: pointer;

  i {
    color: rgba(0, 0, 0, 0.3);
  }

  &:hover {
    background-color: ${({ theme }) => theme.primary.color};

    i {
      color: ${({ theme }) => theme.primary.contrast};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    align-self: center;
  }
`;
