import React, { useEffect } from "react";
import LevelHeader from "../../components/LevelHeader/LevelHeader";
import { Grid, Row, Col, Typography } from "@jcbruno96/react-ui-library";
import HeaderImage from "../../assets/images/_DUA6503.webp";
import Image1 from "../../assets/images/_DUA6499.webp";
import Image2 from "../../assets/images/escuela_verde_actividad.jpeg";
import KinderAward from "../../assets/images/escuela_verde_inicial.webp";
import PrimaryAward from "../../assets/images/escuela_verde_primaria.webp";
import SecondaryAward from "../../assets/images/escuela_verde_secundaria.webp";
import GerminaAward from "../../assets/images/premio_germina.webp";
import PhotoAndText from "../../components/PhotoAndText/PhotoAndText";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import styled, { useTheme } from "styled-components";
import { Title } from "../../components/Title/Title";
import { Highlight } from "../../components/Highlight/Highlight";
import { useDispatch } from "react-redux";
import { actions } from "../../redux/loading";
import { useScrollAnimation } from "../../hooks/useScrollAnimation";

const GreenSchool = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { reveal } = useScrollAnimation(
    document.getElementById("root"),
    "green-school-container"
  );

  useEffect(() => {
    dispatch(actions.setLoading(false));
    reveal();
  }, [dispatch, reveal]);

  return (
    <div className="green-school-container">
      <LevelHeader
        title="Proyecto Educativo Ambiental"
        description="Nuestro proyecto ambiental surge de una gran preocupación por el medio
        ambiente. Consideramos esencial para nuestro futuro poder, desde nuestro
        lugar, promover un cambio donde el medio ambiente y su cuidado sea nuestro
        gran objetivo desde hoy y para siempre como Institución Educativa."
        imageUrl={HeaderImage}
      />
      <StyledPageContainer dark style={{ minHeight: "30vh" }}>
        <Grid>
          <Row style={{ justifyContent: "center" }}>
            <Col
              xs={12}
              md={10}
              lg={8}
              style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Title style={{ textAlign: "center" }}>
                Escuela Consagrada <Highlight>Etapa II</Highlight>
              </Title>
              <Typography
                variant="h5"
                style={{ color: theme.secondary.contrast }}
              >
                <>
                  Se reconoce al{" "}
                  <b>Instituto Educacional Duayen - A-990 - Nivel Inicial</b>{" "}
                  por su compromiso con la educación para la sustentabilidad en
                  pos de la construcción de un futuro más sustentable, justo,
                  equitativo, diverso y en paz.
                </>
              </Typography>
            </Col>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Col xs={12} md={10} lg={6} style={{ justifyContent: "center" }}>
              <Image src={KinderAward} />
            </Col>
            <Col xs={12} md={10} lg={6} style={{ justifyContent: "center" }}>
              <Image src={PrimaryAward} />
            </Col>
            <Col xs={12} md={10} lg={6} style={{ justifyContent: "center" }}>
              <Image src={SecondaryAward} />
            </Col>
          </Row>
        </Grid>
      </StyledPageContainer>
      <PhotoAndText
        title="Premio Germina"
        lettersHighlighted={7}
        description={[
          "Nos enorgullece recibir la distinción del Premio Germina por el proyecto llevado a cabo desde nivel Inicial 'La polinización: Somos los protagonistas'.",
          "El mismo tiene a la Biodiversidad como el hilo conductor al que se refiere el abordaje de polinizadores silvestres y flora nativa como actores claves en la sustentabilidad del ecosistema...",
        ]}
        imageUrl={GerminaAward}
        buttonText="Leer más del proyecto"
        onClick={() => window.open("/assets/files/Germina 2022.pdf", "_blank")}
      />
      <PhotoAndText
        title=""
        isReverse
        dark
        description={[
          "Nuestro propósito es, en primer lugar, concientizar al personal docente y no docente de la Institución, para que ellos luego puedan propagar con entusiasmo estas nociones a toda nuestra comunidad. Pensamos en los estudiantes como el puente para llegar a toda la comunidad, para transmitir la conciencia, los motivos y maneras de preservar nuestro medio ambiente y nuestros recursos naturales.",
          "Creemos que, llevando a cabo hechos concretos desde el Instituto, como informar, concientizar y educar, aportaremos conciencia y compromiso a nuestra comunidad.",
          "Incorporamos nuestro proyecto ambiental al PEI (Proyecto Educativo Institucional), a la planificación anual y en los registros narrativos como también en proyectos y actividades concretas permitiendo integrarlos a las diversas áreas de aprendizaje en todos nuestros niveles.",
        ]}
        imageUrl={Image1}
      />
      <PhotoAndText
        title=""
        description={[
          "Nuestro objetivo es generar un compromiso con el cuidado del medio ambiente desde los primeros años de escolaridad, en los cuales los niños comienzan a interactuar socialmente y así podrán tener contacto con saberes, vivencias, actitudes y valores desarrollados culturalmente. Por tal motivo, creemos necesario, hacer hincapié en la educación ambiental, lo que ella representa para el bien del planeta y poder adoptarlo como forma de vida.",
          "Nuestra idea es darle sentido a los principios de reciclado y reutilización, mediante el ejemplo y compromiso. Los materiales de descarte son recursos     valiosos, que requieren de un proceso de clasificación para poder volver a utilizarlos en el jardín o bien para entregarlo a recuperadores urbanos, quienes recogen todo el material clasificado.",
          "El cambio es necesario y desde nuestro lugar de educadores tenemos que continuar creando y fomentando este cambio de forma de vida y cuidados del medio ambiente, donde no solo quede en palabras sino en hechos.",
        ]}
        imageUrl={Image2}
      />
      <StyledPageContainer dark>
        <Blockquote>
          Estamos totalmente convencidos que el cambio comienza desde el Jardín.
        </Blockquote>
        <Cite>Instituto Duayen</Cite>
      </StyledPageContainer>
    </div>
  );
};

export default GreenSchool;

const StyledPageContainer = styled(PageContainer)`
  min-height: 50vh;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  margin: 20px;
`;

const Blockquote = styled.blockquote`
  font-weight: 100;
  font-size: 2rem;
  max-width: 600px;
  line-height: 1.4;
  position: relative;
  margin: 0;
  padding: 0.5rem;
  font-family: "Special Elite", cursive;

  &:before,
  &:after {
    position: absolute;
    color: ${({ theme }) => theme.primary.color};
    font-size: 8rem;
    width: 4rem;
    height: 4rem;
  }

  &:before {
    content: "“";
    left: -6rem;
    top: -2rem;
  }

  &:after {
    content: "”";
    right: -5rem;
    bottom: 1rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    &:before,
    &:after {
      font-size: 3rem;
    }

    &:before {
      left: -1rem;
      top: -2rem;
    }

    &:after {
      right: -2rem;
      bottom: 0rem;
    }
  }
`;

const Cite = styled.cite`
  line-height: 3;
  font-size: 20px;
  text-align: left;
`;
