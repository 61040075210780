import moment from "moment";
import "moment/locale/es";
import DOMPurify from "dompurify";
import { useNewsDetail } from "./useNewsDetail";
import {
  StyledPageContainer,
  NewsImage,
  NewsTitle,
  NewsSubtitle,
  NewsDate,
  NewsContent,
  NewsCategory,
} from "./styles";
import { useMemo } from "react";
import { API_URL } from "../../utils/constants";

const NewsDetail = () => {
  const { news } = useNewsDetail();

  const imageUrl = useMemo(() => {
    return news && news?.news_files && news?.news_files?.length > 0
      ? news?.news_files[0].files?.url
      : "";
  }, [news]);

  const newsSection = useMemo(() => {
    const section = news?.section;
    return section === "ingles"
      ? "Inglés"
      : section === "primaria"
      ? "Nivel Primario"
      : section === "secundaria"
      ? "Nivel Medio"
      : "Nivel Inicial";
  }, [news]);

  const content = useMemo(() => {
    return news?.content.replace(/\/uploads/g, `${API_URL}/uploads`) || "";
  }, [news]);

  return (
    <StyledPageContainer id="news-detail-container">
      <NewsCategory>{newsSection}</NewsCategory>
      <NewsTitle>{news?.title}</NewsTitle>
      <NewsSubtitle>{news?.preview_text}</NewsSubtitle>
      <NewsDate>
        {news?.date
          ? moment(news?.date, "YYYY-MM-DD")
              .locale("es")
              .format("DD MMMM, YYYY")
          : ""}
      </NewsDate>
      <NewsImage src={imageUrl} alt="news-image" />
      <NewsContent
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(content, { USE_PROFILES: { html: true } }),
        }}
      >
        {/* <ReactMarkdown>{news?.attributes?.content || ""}</ReactMarkdown> */}
      </NewsContent>
    </StyledPageContainer>
  );
};

export default NewsDetail;
