import styled from "styled-components";
export const Subtitle = styled.h4`
  position: relative;
  font-size: 2.6rem;
  width: 100%;
  margin: 0;
  cursor: default;
  text-align: left;
  line-height: 3rem;
  margin-bottom: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  @media (max-width: 576px) {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`;
