import { NewsCategory } from "../../interfaces/news";

export const categories = [
  {
    id: NewsCategory.Kindergarten,
    label: "Inicial"
  },
  {
    id: NewsCategory.Primary,
    label: "Primaria"
  },
  {
    id: NewsCategory.Secondary,
    label: "Secundaria"
  },
  {
    id: NewsCategory.English,
    label: "Inglés"
  }
]