import { ApiResponse } from "../interfaces/api";
import { News, NewsFilter } from "../interfaces/news";
import { client } from "./api";

export const getNewsDetail = (id: number) => {
  return client.get<ApiResponse<News>>(`/news/detail/${id}`);
};

export const getNews = (params: NewsFilter) => {
  return client.get<ApiResponse<News[]>>('/news', {params});
};
