import React from "react";
import { Typography } from "@jcbruno96/react-ui-library";
import { CardProps } from "./types";
import {
  Container,
  ImageContainer,
  Image,
  InfoContainer,
  Text,
} from "./styles";

const Card = ({
  image,
  title,
  description,
  color = "dark",
  onClick,
}: CardProps) => {
  return (
    <Container onClick={onClick}>
      <ImageContainer color={color}>
        <Image src={image} alt="background-image" />
      </ImageContainer>
      <InfoContainer>
        <Text>
          <Typography variant="h4" style={{ marginBottom: 8, color: "#fff" }}>
            {title}
          </Typography>
          <Typography variant="h6" style={{ color: "#fff" }}>
            {description}
          </Typography>
        </Text>
      </InfoContainer>
    </Container>
  );
};

export default Card;
