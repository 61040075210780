import { Icon, ThemeColors, Typography } from "@jcbruno96/react-ui-library";
import React from "react";
import styled, { useTheme } from "styled-components";

type Props = {
  color?: ThemeColors;
  children: JSX.Element | JSX.Element[];
};

const Time = ({ children, color = "dark" }: Props) => {
  const theme = useTheme();
  return (
    <Card color={color}>
      <Icon color={theme[color].color} icon="clock" type="regular" size={24} />

      <Typography
        variant="h6"
        style={{ marginTop: 8, color: theme.secondary.contrast }}
      >
        <>{children}</>
      </Typography>
    </Card>
  );
};

export default Time;

const Card = styled.div<{ color: ThemeColors }>`
  border: 2px solid ${({ theme, color }) => theme[color].color};
  background-color: ${({ theme }) => theme.light.color};
  border-style: dashed;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: auto;
  margin: 8px 0;
  height: 100%;
`;
