import Admission from "../../components/Admission/Admission";
import { LevelsEnum } from "../../components/Admission/useForm";
import LevelHeader from "../../components/LevelHeader/LevelHeader";
import PhotoAndText from "../../components/PhotoAndText/PhotoAndText";
import HeaderImage from "../../assets/images/_DUA6674.webp";
import Image1 from "../../assets/images/_DUA5001.webp";
import Image2 from "../../assets/images/_DUA5005.webp";
import Image3 from "../../assets/images/ingles_1.webp";
import Information from "./Information/Information";

import { Section, ScrollSection } from "../../components";
import News from "../../components/News/News";
import { useLevelController } from "../../hooks/useLevelController";
import { NewsCategory } from "../../interfaces/news";

const Primary = () => {
  const {
    history,
    news,
    scrollSectionRef,
    actualOption,
    scrollToAdmission,
    onAnimationEnd,
    onSectionChanged,
  } = useLevelController("primary-container", NewsCategory.Primary);
  return (
    <div className="primary-container" id="primary-container">
      <ScrollSection
        ref={scrollSectionRef}
        onSectionChanged={onSectionChanged}
        onAnimationEnd={onAnimationEnd}
      >
        <Section>
          <LevelHeader
            title="Nivel Primario"
            description="Plan de Jornada Completa con Intensificación idiomática (Programa de Escuelas con Intensificación de Inglés, aprobado por el Ministerio de Educación de la Ciudad de Buenos Aires)."
            imageUrl={HeaderImage}
            onClick={scrollToAdmission}
          />
        </Section>
        <Section>
          <PhotoAndText
            title="Proyecto Educativo"
            id="proyecto-educativo-primaria"
            dark
            description={[
              "Propuesta con diez horas semanales de inglés en cada sección (Convenio con BAOC. Universidad de Cambridge).",
              "Informática y Robótica - Expresión corporal - Teatro - Taller de periodismo - Taller de narración y medios - Técnicas de estudio - Música - Arte",
            ]}
            imageUrl={Image1}
            isVisible={actualOption === 1}
            tags={["robótica", "inglés"]}
            lettersHighlighted={8}
          />
        </Section>
        <Section>
          <PhotoAndText
            isReverse
            id="proyecto-deportivo-primaria"
            title="Proyecto Deportivo"
            description={[
              "Actividades deportivas. Campo de deportes Club Cirse con traslados. Primer ciclo (martes y jueves) - Segundo ciclo (lunes y miércoles)",
              "Natación en nuestro instituto desde primero a cuarto grado. Natación en Club Monroe de quinto a séptimo grado. (con traslados, dentro de la jornada escolar)",
              "Práctica deportiva en agrupamientos mixtos. Iniciación al deporte (Fútbol, Hockey, Voley, Handball, Atletismo, Softbol y Basquet)",
              "Participación en torneos intercolegiales (CABA). Fútbol y Hockey.",
            ]}
            imageUrl={Image2}
            isVisible={actualOption === 2}
            tags={["natatorio", "intercolegiales"]}
            lettersHighlighted={8}
          />
        </Section>
        <Section>
          <PhotoAndText
            title="Inglés"
            dark
            id="ingles-primaria"
            lettersHighlighted={10}
            description={[
              "Carga horaria de 10 horas semanales de inglés de 1º a 7º grado.",
              "Reconocimiento por parte del Ministerio de Educación como Escuela con Intensificación de Idioma Inglés, por lo que al culminar el nivel primario nuestros estudiantes reciben un Certificado Oficial.",
            ]}
            imageUrl={Image3}
            isVisible={actualOption === 3}
            buttonText="Conocer más"
            onClick={() => history.push("ingles/primaria")}
          />
        </Section>
        <Section>
          <Information isVisible={actualOption === 4} />
        </Section>
        {news?.length > 0 ? (
          <Section>
            <News
              dark
              news={news}
              isVisible={actualOption === 5}
              section="primaria"
            />
          </Section>
        ) : null}
        <Section>
          <Admission
            level={LevelsEnum.PRIMARY}
            isVisible={
              (actualOption === 6 && news?.length > 0) ||
              (actualOption === 5 && news?.length === 0)
            }
          />
        </Section>
      </ScrollSection>
    </div>
  );
};

export default Primary;
