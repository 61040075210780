import { Col, Grid, Row, useResponsive } from "@jcbruno96/react-ui-library";
import { useCallback, useEffect, useRef } from "react";
import NewsCard from "../NewsCard/NewsCard";
import { NewsContainer, ViewAll, StyledReveal } from "./styles";
import { NewsProps } from "./types";
import { Title } from "../Title/Title";
import { Highlight } from "../Highlight/Highlight";
import Reveal from "../Reveal/Reveal";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions } from "../../redux/loading";
import { delayMs } from "../../utils/delay";
import { BREAKPOINT_MD, INITIAL_LOADING_TIME } from "../../utils/constants";
import { MenuOptionEnum } from "../Navbar/types";

const News = ({
  isVisible,
  id = "news-section",
  news,
  dark,
  section = "",
  showTitle = true,
  showViewAll = true,
}: NewsProps) => {
  const firstRender = useRef<boolean>(true);
  const history = useHistory();
  const { isMobile } = useResponsive(BREAKPOINT_MD);
  const dispatch = useDispatch();

  const viewAll = useCallback(async () => {
    dispatch(actions.setLoading(true));
    await delayMs(INITIAL_LOADING_TIME + 100);
    dispatch(actions.setActualOption(MenuOptionEnum.News));
    if(section !== "") {
      history.push(`/noticias/${section}`);
    } else {
      history.push(`/noticias`);
    }
  }, [history, dispatch, section]);

  const reveal = useCallback(() => {
    var reveals = document.querySelectorAll(`#${id} .reveal`);
    for (var i = 0; i < reveals.length; i++) {
      reveals[i].classList.add("active");
    }
  }, [id]);

  useEffect(() => {
    if (isVisible && firstRender.current) {
      reveal();
      firstRender.current = false;
    }
  }, [isVisible, reveal, news]);

  useEffect(() => {
    if (news?.length > 0 && !firstRender.current) {
      reveal();
    }
  }, [news, reveal]);

  const renderNews = useCallback(() => {
    return news.map((news, index) => (
      <Col key={news.id} xs={12} sm={6} lg={6} xl={3}>
        <StyledReveal delay={isMobile ? 0 : 500 + index * 100}>
          <NewsCard news={news} />
        </StyledReveal>
      </Col>
    ));
  }, [news, isMobile]);

  return (
    <NewsContainer id={id} dark={dark}>
      {showTitle && (
        <Reveal delay={isMobile ? 0 : 500}>
          <Title>
            Últimas <Highlight>noticias</Highlight>
          </Title>
        </Reveal>
      )}
      <Grid>
        <Row>{renderNews()}</Row>
      </Grid>
      {showViewAll && (
        <Reveal delay={isMobile ? 0 : 700} offset={40}>
          <ViewAll onClick={viewAll}>Ver todas</ViewAll>
        </Reveal>
      )}
    </NewsContainer>
  );
};

export default News;
