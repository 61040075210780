import styled from "styled-components";
import { colorOpacity, ThemeColors } from "@jcbruno96/react-ui-library";
import { CardShadow } from "../../../styles";

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ImageContainer = styled.div<{ color: ThemeColors }>`
  overflow: hidden;
  width: 100%;
  //height: 100%;
  position: relative;
  will-change: transform;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* background-color: ${({ theme, color }) =>
      colorOpacity(theme[color].color, 0.8)}; */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &::before {
    content: "";
    display: block;
  }
`;

export const InfoContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0.25rem;

  display: flex;

  opacity: 0;
  transition: opacity 0.2s ease-out;
  will-change: opacity;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

export const Text = styled.div`
  margin: auto;
  color: white;
  padding: 24px;
  text-align: center;
`;

export const Container = styled.div`
  display: inline-flex;
  position: relative;
  width: 100%;
  height: 380px;
  //border-radius: 8px;
  overflow: hidden;
  ${CardShadow}
  cursor: pointer;

  & ${Image} {
    transition: transform 0.4s cubic-bezier(0.71, 0.05, 0.29, 0.9);
    transform: scale(1) translate(0, 0);
  }

  & ${ImageContainer}::after {
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.71, 0.05, 0.29, 0.9);
    will-change: opacity;
  }

  &:hover ${Image} {
    transform: scale(1.2) translate(4%, 4%);
  }

  &:hover ${ImageContainer}::after {
    opacity: 1;
  }

  &:hover ${InfoContainer} {
    opacity: 1;
    transition-delay: 0.3s;
  }
`;
