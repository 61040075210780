import { Icon } from "@jcbruno96/react-ui-library";
import styled, { css } from "styled-components";

export const Container = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  padding: 0 8px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Documents = styled.div`
  overflow-y: auto;
  max-height: 100%;
  flex: 1;
  height: 100%;
  padding: 8px;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

export const StyledIcon = styled(Icon)<{ disabled: boolean }>`
  transition: color 0.25s ease-in-out;
  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: default;
        color: #ccc;
      `;
    }

    return css`
      cursor: pointer;
      color: #2d2d2d;
    `;
  }}
`;
