import { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { News, NewsCategory } from "../interfaces/news";
import { actions } from "../redux/loading";
import { getNews } from "../services/news";
import { INITIAL_LOADING_TIME } from "../utils/constants";
import { delayMs } from "../utils/delay";
import { onImagesLoaded } from "../utils/images";
import { useScrollAnimation } from "./useScrollAnimation";

export const useLevelController = (id: string, category: NewsCategory) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [news, setNews] = useState<News[]>([]);
  const [actualOption, setActualOption] = useState(0);
  const scrollSectionRef = useRef<any>();
  const firstRenderRef = useRef<boolean>(false);
  const animationEndRef = useRef(true);
  const { reveal } = useScrollAnimation(document.getElementById("root"), id);

  const scrollToAdmission = () => {
    if (scrollSectionRef.current) {
      if (news?.length > 0) {
        setActualOption(6);
        scrollSectionRef.current.scroll(6, false);
      } else {
        setActualOption(5);
        scrollSectionRef.current.scroll(5, false);
      }
    }
  };

  const onSectionChanged = useCallback((section: number) => {
    animationEndRef.current = false;
    setActualOption(section);
  }, []);

  const onAnimationEnd = useCallback(() => {
    animationEndRef.current = true;
  }, []);

  const getLastNews = useCallback(async () => {
    try {
      const { data } = await getNews({ category, limit: 4 });
      setNews(data?.result || []);
    } catch (err) {
      setNews([]);
      console.log("Images err: ", err);
      throw err;
    }
  }, [category]);

  const initialRender = useCallback(async () => {
    try {
      await getLastNews();

      await delayMs(500);

      const images = document.querySelector(`#${id} .level-header`) as Element;
      if (images) await onImagesLoaded(images);

      await delayMs(500);
    } catch (err) {
      console.log("Home error: ", err);
    }
  }, [getLastNews, id]);

  const initialFetch = useCallback(async () => {
    await Promise.all([initialRender(), delayMs(INITIAL_LOADING_TIME)]);
    dispatch(actions.setLoading(false));
  }, [initialRender, dispatch]);

  useEffect(() => {
    if (!firstRenderRef.current) {
      initialFetch();
      firstRenderRef.current = true;
    }
  }, [initialFetch]);

  useEffect(() => {
    dispatch(actions.setLoading(false));
    reveal();
  }, [dispatch, reveal]);

  return {
    history,
    scrollSectionRef,
    actualOption,
    news,
    scrollToAdmission,
    onAnimationEnd,
    onSectionChanged,
    setActualOption,
  };
};
