export enum MenuOptionEnum {
  Home = "",
  AboutUs = "nosotros",
  Kindergarten = "inicial",
  Primary = "primaria",
  Secondary = "secundaria",
  English = "ingles",
  Admission = "admision",
  News = "noticias",
  Contact = "contacto"
}


export type NavbarProps = {
  className?: string;
  animated?: boolean;
  actualOption?: MenuOptionEnum;
  onOptionClick?: (option: MenuOptionEnum) => void;
}