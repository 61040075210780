import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import PhotoAndText from "../../components/PhotoAndText/PhotoAndText";
import Timeline from "./Timeline/Timeline";
import Image from "../../assets/images/_DUA6487.webp";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { actions } from "../../redux/loading";
import { delayMs } from "../../utils/delay";

const AboutUs = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const reveal = useCallback(() => {
    var reveals = document.querySelectorAll(`#about-us-container .reveal`);
    for (var i = 0; i < reveals.length; i++) {
      reveals[i].classList.add("active");
    }
  }, []);

  const initialRender = useCallback(async () => {
    await delayMs(1000);
    reveal();
    dispatch(actions.setLoading(false));
  }, [dispatch, reveal]);

  useEffect(() => {
    initialRender();
  }, [initialRender]);

  return (
    <Container id="about-us-container">
      <Timeline />
      <PhotoAndText
        title="Departamento Ecológico"
        lettersHighlighted={12}
        description={[
          "Proyecto liderado por dos de nuestros más antiguos y queridos docentes, que atraviesa toda nuestra estructura.",
          "Nivel Inicial - Escuela consagrada ETAPA II",
          "Nivel Primario - Lazo Ambiental IV ESCUELA MULTIPLICADORA",
          "Nivel Medio - Lazo Ambiental III",
        ]}
        imageUrl={Image}
        buttonText="Conocer más"
        onClick={() => history.push("/escuela-verde")}
      />
    </Container>
  );
};

export default AboutUs;

const Container = styled.div`
  padding-top: 60px;
  background-color: ${({ theme }) => theme.dark.color};
`;
