import styled, { keyframes, css } from "styled-components";

const FadeIn = keyframes`
  0% { 
    opacity: 0; 
    visibility: hidden;
  }
  100% { 
    opacity: 1; 
    visibility: visible; 
  }
`;

const FadeOut = keyframes`
  0% { 
    opacity: 1; 
    visibility: visible;
  }
  100% { 
    opacity: 0; 
    visibility: hidden;
  }
`;

const FadeInRight = keyframes`
  0% { 
    opacity: 0;
    transform: translateX(64px);
  }
  100% { 
    opacity: 1;
    transform: translateX(0);
  }
`;

const FadeOutRight = keyframes`
  0% { 
    opacity: 1;
    transform: translateX(0px);
  }
  100% { 
    opacity: 0;
    transform: translateX(64px);
  }
`;

export const MenuOption = styled.h1`
  margin: 0;
  opacity: 0;
  color: #fff;
  font-size: 56px;
  line-height: 64px;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.primary.color};
    transition: color 0.3s ease;
  }

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.sm}px) {
      font-size: 40px;
      line-height: 48px;
    }
  `}
`;

export const MenuContainer = styled.div`
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 80px 80px 0 80px;
  text-align: right;
  visibility: hidden;
  opacity: 0;
  
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/ opacity */
  
  
  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.sm}px) {
      padding: 32px;
    }
  `}

  &.active {
    animation: ${FadeIn} 300ms ease;
    animation-fill-mode: forwards;


    ${MenuOption} {
      animation: ${FadeInRight} 300ms ease 150ms;
      animation-fill-mode: forwards;

      &:nth-child(3) {
        animation-delay: 200ms;
      }
      &:nth-child(4) {
        animation-delay: 250ms;
      }
      &:nth-child(5) {
        animation-delay: 300ms;
      }
      &:nth-child(6) {
        animation-delay: 350ms;
      }
      &:nth-child(7) {
        animation-delay: 400ms;
      }
      &:nth-child(8) {
        animation-delay: 450ms;
      }
      &:nth-child(9) {
        animation-delay: 500ms;
      }
    }
  }

  &.inactive {
    animation: ${FadeOut} 300ms ease;
    animation-fill-mode: forwards;

    ${MenuOption} {
      visibility: hidden;
      animation: ${FadeOutRight} 300ms ease;
      animation-fill-mode: forwards;
    }
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 42px;
  right: 60px;
  cursor: pointer;
  animation: ${FadeIn} 250ms ease;

  &:hover > svg line {
    stroke: ${({ theme }) => theme.primary.color};
    transition: stroke 0.3s ease;
  }

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.sm}px) {
      top: 14px;
      right: 18px;

      & > svg {
        width: 32px;
        height: 32px;
      }
    }
  `}
`;
