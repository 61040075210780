import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

export enum LevelsEnum {
  ALL = "",
  KINDERGARTEN = "1",
  PRIMARY = "2",
  SECONDARY = "3",
}

type AdmissionForm = {
  level: string;
  course: string;
  fullname: string;
  email: string;
  phone: string;
  extraMessage: string;
};

type Course = {
  id: number;
  name: string;
};

const kindergartenCourses: Course[] = [
  { id: 1, name: "Sala de 2" },
  { id: 2, name: "Sala de 3" },
  { id: 3, name: "Sala de 4" },
  { id: 4, name: "Sala de 5" },
];

const primaryCourses: Course[] = [
  { id: 1, name: "1° grado" },
  { id: 2, name: "2° grado" },
  { id: 3, name: "3° grado" },
  { id: 4, name: "4° grado" },
  { id: 5, name: "5° grado" },
  { id: 6, name: "6° grado" },
  { id: 7, name: "7° grado" },
];

const secondaryCourses: Course[] = [
  { id: 1, name: "1° año" },
  { id: 2, name: "2° año" },
  { id: 3, name: "3° año" },
  { id: 4, name: "4° año" },
  { id: 5, name: "5° año" },
];

const levels = [
  {
    id: Number(LevelsEnum.KINDERGARTEN),
    name: "Inicial",
    courses: kindergartenCourses,
  },
  { id: Number(LevelsEnum.PRIMARY), name: "Primario", courses: primaryCourses },
  {
    id: Number(LevelsEnum.SECONDARY),
    name: "Secundario",
    courses: secondaryCourses,
  },
];

export const useForm = (
  level: string,
  callback: (status: "sending" | "error" | "success") => void
) => {
  const [courses, setCourses] = useState<Course[]>([]);
  const form = useFormik<AdmissionForm>({
    initialValues: {
      level: level,
      course: "",
      fullname: "",
      email: "",
      phone: "",
      extraMessage: "",
    },
    validationSchema: Yup.object({
      level: Yup.string().required("Campo requerido"),
      course: Yup.string().required("Campo requerido"),
      fullname: Yup.string().required("Campo requerido"),
      email: Yup.string().email("Formato invalido").required("Campo requerido"),
      phone: Yup.string().required("Campo requerido"),
      extraMessage: Yup.string(),
    }),
    onSubmit: (values: AdmissionForm) => {
      sendEmail(values);
    },
  });

  const sendEmail = async (values: AdmissionForm) => {
    callback("sending");
    const body = {
      transporter: "SMTP",
      subject: "Solicitud de admisión vía web",
      fullname: values.fullname,
      level: levels.find((level) => level.id === parseInt(values.level))?.name,
      course: courses.find((course) => course.id === parseInt(values.course))
        ?.name,
      email: values.email,
      phone: values.phone,
      extraMessage: values.extraMessage,
    };

    try {
      let response = await fetch(
        `/api/email/`,
        {
          method: "POST",
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(body), // body data type must match "Content-Type" header
        }
      );
      if (response.status >= 200 && response.status < 300) callback("success");
      else callback("error");
    } catch (err) {
      console.log("Error: ", err);
      callback("error");
    }
  };

  useEffect(() => {
    if (form.values.level) {
      const level = levels.find((l) => l.id === Number(form.values.level));
      if (level) setCourses(level.courses);
      else setCourses([]);
    } else setCourses([]);
  }, [form.values.level]);

  return {
    form,
    levels,
    courses,
  };
};
