import { MenuContainer, MenuOption, IconContainer } from "./styles";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import { MenuProps } from "./types";
import { MenuOptionEnum } from "../Navbar/types";
import { useCallback, useEffect, useRef } from "react";
import { INITIAL_LOADING_TIME, MENU_OPTIONS } from "../../utils/constants";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/loading";
import { delayMs } from "../../utils/delay";
import { RootState } from "../../redux/store";

const Menu = ({ show, onClose, onOptionClick }: MenuProps) => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const firstRender = useRef<boolean>(true);
  const { pathname } = useLocation();
  const { actualOption } = useSelector((state: RootState) => state.loading);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOptionClicked = useCallback(
    async (option: MenuOptionEnum) => {
      const isNewsDetail =
        actualOption === MenuOptionEnum.News && pathname !== "/noticias";
      if (actualOption !== option || isNewsDetail) {
        dispatch(actions.setLoading(true));
        await delayMs(INITIAL_LOADING_TIME + 100);
        if (onOptionClick) onOptionClick(option);
        dispatch(actions.setActualOption(option));
        history.push(`/${option}`);
      } 
      
      onClose();
    },
    [actualOption, dispatch, onOptionClick, history, onClose, pathname]
  );

  const renderOptions = useCallback(() => {
    return MENU_OPTIONS.map((option) => (
      <MenuOption
        key={option.id}
        onClick={() => handleOptionClicked(option.id)}
      >
        {option.label}
      </MenuOption>
    ));
  }, [handleOptionClicked]);

  useEffect(() => {
    if (!menuRef.current) return;
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (show) {
      menuRef.current.classList.add("active");
      menuRef.current.classList.remove("inactive");
    } else {
      menuRef.current.classList.remove("active");
      menuRef.current.classList.add("inactive");
    }
  }, [show]);

  return (
    <MenuContainer ref={menuRef}>
      <IconContainer onClick={onClose}>
        <CloseIcon height={50} width={50} />
      </IconContainer>
      {renderOptions()}
    </MenuContainer>
  );
};

export default Menu;
