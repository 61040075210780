import styled from "styled-components";
import { Button } from "../Button/Button";

export const Container = styled.div<{ isReverse?: boolean; dark?: boolean }>`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: ${({ isReverse }) => (isReverse ? "row-reverse" : "row")};
  background-color: ${({ dark, theme }) =>
    dark ? theme.secondary.color : theme.light.color};
  color: ${({ theme }) => theme.light.contrast};

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const Section = styled.div<{ padding?: boolean }>`
  min-height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  float: left;
  padding: ${({ padding }) => (padding ? "32px 5%" : "24px")};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ImageContainer = styled.div<{ isReverse?: boolean }>`
  width: 90%;
  height: 90%;
  max-height: 90%;

  & > div {
    height: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 70%;
    height: 70%;
    max-height: 70%;
    transform: ${({ isReverse }) =>
      isReverse ? "rotate(-3deg)" : "rotate(3deg)"};
  }
`;

export const Description = styled.p`
  position: relative;
  font-size: 1.05rem;
  max-width: 1200px;
  width: 100%;
  cursor: default;
  text-align: left;
  letter-spacing: 1px;
  margin-bottom: 1.5rem;
  text-align: justify;
  padding-left: 12px;
  border-left: 4px solid ${({ theme }) => theme.primary.color};

  @media (max-width: 768px) {
    font-size: 1.05rem;
  }

  @media (max-width: 576px) {
    font-size: 0.95rem;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 32px;
  align-self: flex-start;
`;
