import { useResponsive } from "@jcbruno96/react-ui-library";
import { useCallback, useEffect, useRef } from "react";
import { BREAKPOINT_MD } from "../../utils/constants";
import { Highlight } from "../Highlight/Highlight";
import Reveal from "../Reveal/Reveal";
import { Title } from "../Title/Title";
import {
  Container,
  Description,
  Image,
  ImageContainer,
  Section,
  StyledButton,
} from "./styles";
import { PhotoAndTextProps } from "./types";

const PhotoAndText = ({
  id,
  title,
  description,
  imageUrl,
  isReverse,
  dark = false,
  lettersHighlighted = 3,
  buttonText,
  isVisible,
  onClick,
}: PhotoAndTextProps) => {
  const firstRender = useRef<boolean>(true);
  const { isMobile } = useResponsive(BREAKPOINT_MD);

  const reveal = useCallback(() => {
    var reveals = document.querySelectorAll(`#${id} .reveal`);
    for (var i = 0; i < reveals.length; i++) {
      reveals[i].classList.add("active");
    }
  }, [id]);

  useEffect(() => {
    if (isVisible && firstRender.current) {
      reveal();
      firstRender.current = false;
    }
  }, [isVisible, reveal]);

  return (
    <Container isReverse={isReverse} dark={dark} id={id}>
      <Section padding>
        <Reveal>
          <Title>
            {title.substring(0, lettersHighlighted)}
            <Highlight>{title.substring(lettersHighlighted)}</Highlight>
          </Title>
        </Reveal>
        <br />
        {description?.map((text, index) => (
          <Reveal key={index} delay={isMobile ? 0 : 100 + index * 100}>
            <Description key={index}>{text}</Description>
          </Reveal>
        ))}
        {buttonText && (
          <Reveal delay={isMobile ? 0 : description?.length * 100}>
            <StyledButton inverted onClick={() => onClick && onClick()}>
              {buttonText}
            </StyledButton>
          </Reveal>
        )}
      </Section>
      <Section>
        <ImageContainer isReverse={isReverse}>
          <Reveal delay={100}>
            <Image src={imageUrl} />
          </Reveal>
        </ImageContainer>
      </Section>
    </Container>
  );
};

export default PhotoAndText;
