import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useResponsive } from "@jcbruno96/react-ui-library";
import { actions } from "../../redux/loading";
import { RootState } from "../../redux/store";
import { INITIAL_LOADING_TIME } from "../../utils/constants";
import { delayMs } from "../../utils/delay";
import { onImagesLoaded } from "../../utils/images";
import { HomeSectionsEnum } from "./types";
import { News } from "../../interfaces/news";
import { getNews as getNewsApi } from "../../services/news";

import Portada1 from "../../assets/images/portada/portada1.webp";
import Portada2 from "../../assets/images/portada/portada2.webp";
import Portada3 from "../../assets/images/portada/portada3.webp";
import Portada4 from "../../assets/images/portada/portada4.webp";
import Portada5 from "../../assets/images/portada/portada5.webp";
import Portada6 from "../../assets/images/portada/portada6.webp";
import Portada7 from "../../assets/images/portada/portada7.webp";
import Portada8 from "../../assets/images/portada/portada8.webp";
import Portada9 from "../../assets/images/portada/portada9.webp";
import Portada10 from "../../assets/images/portada/portada10.webp";

export const useHome = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.loading);
  const firstRenderRef = useRef<boolean>(false);
  const scrollSectionRef = useRef<any>();
  const animationEndRef = useRef(true);
  const { section = "" } = useParams<{ section: HomeSectionsEnum }>();
  const [news, setNews] = useState<News[]>([]);
  const [actualOption, setActualOption] = useState(HomeSectionsEnum.Carrousel);
  const { isMobile } = useResponsive(992);

  const scrollToSectionMobile = useCallback(
    (option: HomeSectionsEnum, effect = true) => {
      let top = 0;
      const id =
        option === HomeSectionsEnum.Levels
          ? "#levels"
          : option === HomeSectionsEnum.Features
          ? "#features"
          : option === HomeSectionsEnum.News
          ? "#news"
          : "#carrousel-section";
      const container = document.querySelector(id);
      if (container) {
        top = container.getBoundingClientRect()?.top || 0;
      }
      scrollSectionRef.current.scroll(0, effect, -1 * top);
    },
    []
  );

  const onSectionChanged = useCallback((section: number) => {
    const options = Object.values(HomeSectionsEnum);
    animationEndRef.current = false;
    if (options[section] === HomeSectionsEnum.News && news?.length === 0) {
      if (actualOption === HomeSectionsEnum.Admission) {
        setActualOption(options[section - 1]);
      } else {
        setActualOption(options[section + 1]);
      }
    } else {
      setActualOption(options[section]);
    }
  }, [actualOption, news?.length]);

  const onAnimationEnd = useCallback(() => {
    animationEndRef.current = true;
  }, []);

  const images = useMemo(() => {
    if(isMobile) {
      return [
        Portada1,
        Portada5,
        Portada7,
        Portada9
      ]
    }

    return [
      Portada2,
      Portada3,
      Portada4,
      Portada6,
      Portada8,
      Portada10
    ]
  }, [isMobile])

  const getLastNews = useCallback(async () => {
    try {
      const { data } = await getNewsApi({ limit: 4 });
      setNews(data?.result || []);
    } catch (err) {
      setNews([]);
      console.log("Images err: ", err);
      throw err;
    }
  }, []);

  const initialRender = useCallback(async () => {
    try {
      const carrousel = document.querySelector("#home-carrousel") as Element;
      if (carrousel) {
        await Promise.all([
          onImagesLoaded(carrousel),
          getLastNews()
        ])
      } else {
        await getLastNews();
      }
    } catch (err) {
      console.log("Home error: ", err);
    }
  }, [getLastNews]);

  const initialFetch = useCallback(async () => {
    await Promise.all([initialRender(), delayMs(INITIAL_LOADING_TIME)]);
    dispatch(actions.setLoading(false));
  }, [initialRender, dispatch]);

  useEffect(() => {
    if (!firstRenderRef.current) {
      initialFetch();
      firstRenderRef.current = true;
    }
  }, [initialFetch]);

  useEffect(() => {
    if (section) {
      const values = Object.values(HomeSectionsEnum);
      const sectionNumber = values.findIndex((v) => v === section) || 0;
      if (!isNaN(sectionNumber) && sectionNumber > 0 && sectionNumber < 4) {
        setActualOption(section);
        if (isMobile) {
          scrollToSectionMobile(section, false);
        } else {
          scrollSectionRef.current.scroll(sectionNumber, false);
        }
      }
    }
  }, [isMobile, scrollToSectionMobile, section]);

  return {
    isMobile,
    scrollSectionRef,
    loading,
    actualOption,
    news,
    images,
    onSectionChanged,
    onAnimationEnd,
  };
};
