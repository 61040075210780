import { useState, useRef, useCallback, useEffect, useMemo } from "react";
import Hammer from "hammerjs";
import { useTheme } from "styled-components";
import { CarrouselProps, OverlayType } from "./types";
import { ScrollSection, Section } from "../../../components";
import {
  Container,
  Image,
  OverlayImage,
  BackIconContainer,
  NextIconContainer,
  Borders,
  ButtonContainer,
} from "./styles";
import { Icon } from "@jcbruno96/react-ui-library";
import { delayMs } from "../../../utils/delay";
import {
  INITIAL_LOADING_TIME,
  TRANSITION_TIME,
} from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions } from "../../../redux/loading";
import { MenuOptionEnum } from "../../../components/Navbar/types";

const IMAGE_TIMEOUT = 4000;

const Carrousel = ({ isMobile, slides, startAnimation }: CarrouselProps) => {
  const theme = useTheme();
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const animationEndRef = useRef<boolean>(true);
  const scrollSectionRef = useRef<any>();
  const carrouselRef = useRef<HTMLDivElement>(null);
  const hammerRef = useRef<any>(null);
  const activeRef = useRef<number>(1);
  const slidesCountRef = useRef<number>(0);
  const history = useHistory();
  const [, setActive] = useState(1);
  const dispatch = useDispatch();
  const [overlay, showOverlay] = useState<OverlayType>("none");

  const localSlides = useMemo(() => {
    if (slides.length === 0) return [];

    return [slides[slides.length - 1], ...slides, slides[0]];
  }, [slides]);

  const goNext = useCallback(() => {
    if (!animationEndRef.current) return;

    if (activeRef.current < slidesCountRef.current + 1) {
      setActive(activeRef.current + 1);
      scrollSectionRef.current.scroll(activeRef.current + 1);
      if (activeRef.current === slidesCountRef.current) {
        setTimeout(async () => {
          showOverlay("first");
          setActive(1);
          activeRef.current = 1;
          scrollSectionRef.current.scroll(1, false);
          await delayMs(100);
          showOverlay("none");
        }, TRANSITION_TIME);
      }
      activeRef.current += 1;
      animationEndRef.current = false;
    }
  }, []);

  const goPrev = useCallback(() => {
    if (!animationEndRef.current) return;

    if (activeRef.current > 0) {
      setActive(activeRef.current - 1);
      scrollSectionRef.current.scroll(activeRef.current - 1);
      if (activeRef.current === 1) {
        setTimeout(async () => {
          showOverlay("last");
          setActive(slidesCountRef.current);
          activeRef.current = slidesCountRef.current;
          scrollSectionRef.current.scroll(slidesCountRef.current, false);
          await delayMs(100);
          showOverlay("none");
        }, TRANSITION_TIME);
      }
      activeRef.current -= 1;
      animationEndRef.current = false;
    }
  }, []);

  const changeSlide = useCallback(
    async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!animationEndRef.current) return;

      const direction = e.clientX > window.innerWidth / 2 ? "right" : "left";

      if (direction === "right") {
        goNext();
      } else if (direction === "left") {
        goPrev();
      }
      animationEndRef.current = false;
    },
    [goNext, goPrev]
  );

  const automaticAnimation = useCallback(() => {
    intervalRef.current = setInterval(() => goNext(), IMAGE_TIMEOUT);
  }, [goNext]);

  const onAnimationEnd = useCallback(() => {
    animationEndRef.current = true;
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      automaticAnimation();
    }
  }, [automaticAnimation]);

  useEffect(() => {
    if (startAnimation) {
      automaticAnimation();
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [automaticAnimation, slides.length, startAnimation]);

  const unsubscribe = () => {
    if (hammerRef?.current) {
      hammerRef.current.remove("swipeleft", goNext);
      hammerRef.current.remove("swiperight", goPrev);
    }
  };

  const subscribe = () => {
    if (carrouselRef?.current) {
      hammerRef.current = new Hammer(carrouselRef.current);
      hammerRef.current.on("swipeleft", goNext);
      hammerRef.current.on("swiperight", goPrev);
    }
  };

  useEffect(() => {
    slidesCountRef.current = slides?.length || 0;
  }, [slides]);

  useEffect(() => {
    scrollSectionRef.current.scroll(1);
  }, []);

  useEffect(() => {
    subscribe();

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrouselRef]);

  const showLatestNews = useCallback(async () => {
    dispatch(actions.setLoading(true));
    dispatch(actions.setActualOption(MenuOptionEnum.News));
    await delayMs(INITIAL_LOADING_TIME + 100);
    history.push(`/noticias/ingles`);
  }, [dispatch, history]);

  return (
    <Container id="carrousel-section" onClick={changeSlide} ref={carrouselRef}>
      <Borders />
      <ButtonContainer onClick={showLatestNews}>
        <Icon icon="newspaper" color="#2d2d2d" type="regular" />
        ÚLTIMAS NOTICIAS
      </ButtonContainer>
      <ScrollSection
        id="home-carrousel"
        ref={scrollSectionRef}
        direction="horizontal"
        onAnimationEnd={onAnimationEnd}
        fullSize
      >
        {localSlides.map((image, index) => (
          <Section key={index}>
            <Image src={image} alt="carrousel-image" loading="lazy" />
          </Section>
        ))}
      </ScrollSection>
      {slides?.length > 0 ? (
        <>
          <OverlayImage
            visible={overlay === "first"}
            src={slides[0]}
            alt="carrousel-image"
            loading="lazy"
          />
          <OverlayImage
            visible={overlay === "last"}
            src={slides[slides.length - 1]}
            alt="carrousel-image"
            loading="lazy"
          />
        </>
      ) : null}
      <BackIconContainer>
        <Icon
          size={40}
          color={theme.primary.contrast}
          icon="chevron-left"
          onClick={goPrev}
        />
      </BackIconContainer>
      <NextIconContainer>
        <Icon
          size={40}
          color={theme.primary.contrast}
          icon="chevron-right"
          onClick={goNext}
        />
      </NextIconContainer>
    </Container>
  );
};

export default Carrousel;
