import React, { useCallback, useEffect, useRef } from "react";
import { Grid, Row, Col, useResponsive } from "@jcbruno96/react-ui-library";
import { useHistory } from "react-router";
import Card from "../Card/Card";
import { Highlight } from "../../../components/Highlight/Highlight";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import Reveal from "../../../components/Reveal/Reveal";
import { Title } from "../../../components/Title/Title";
import { LevelsProps } from "./types";
import { DUAYEN_LEVELS } from "./cards";
import { useDispatch } from "react-redux";
import { actions } from "../../../redux/loading";
import { delayMs } from "../../../utils/delay";
import { BREAKPOINT_MD, INITIAL_LOADING_TIME } from "../../../utils/constants";

const Levels = ({ isVisible }: LevelsProps) => {
  const firstRender = useRef<boolean>(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const { isMobile } = useResponsive(BREAKPOINT_MD);

  const reveal = () => {
    var reveals = document.querySelectorAll("#levels-container .reveal");
    for (var i = 0; i < reveals.length; i++) {
      reveals[i].classList.add("active");
    }
  };

  const onCardClicked = useCallback(
    async (path: string) => {
      dispatch(actions.setLoading(true));
      await delayMs(INITIAL_LOADING_TIME + 100);
      history.push(path);
    },
    [dispatch, history]
  );

  useEffect(() => {
    if (isVisible && firstRender.current) {
      reveal();
      firstRender.current = false;
    }
  }, [isVisible]);

  const renderLevels = useCallback(() => {
    return DUAYEN_LEVELS.map((level, index) => {
      return (
        <Col key={index} sm={12} md={6} lg={4} style={{ padding: 16 }}>
          <Reveal delay={isMobile ? 0 : 600 + index * 150}>
            <Card
              onClick={() => onCardClicked(level.path)}
              image={level.photo}
              title={level.title}
              description={level.description}
            />
          </Reveal>
        </Col>
      );
    });
  }, [onCardClicked, isMobile]);

  return (
    <PageContainer id="levels-container" dark>
      <Reveal delay={isMobile ? 0 : 500}>
        <Title>
          Nuestra <Highlight>propuesta</Highlight>
        </Title>
      </Reveal>
      <Grid>
        <Row>{renderLevels()}</Row>
      </Grid>
    </PageContainer>
  );
};

export default Levels;
