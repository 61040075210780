import { Icon, Typography } from "@jcbruno96/react-ui-library";
import {
  StyledPageContainer,
  CopyrightContainer,
  SocialMedia,
} from "./styles";

const Footer = () => {
  return (
    <StyledPageContainer>
      <CopyrightContainer>
        <Typography variant="h6" style={{ color: "black" }}>
          Copyright © 2021 Todos los derechos reservados por Instituto Duayen.
          Creado por J2Dev.
        </Typography>
        <SocialMedia>
          <a
            href="https://www.instagram.com/eco_duayen/"
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon="instagram" type="brands" size={24} />
          </a>
        </SocialMedia>
      </CopyrightContainer>
    </StyledPageContainer>
  );
};

export default Footer;
