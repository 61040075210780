import styled, { css, keyframes } from "styled-components";
import { NAVBAR_HEIGHT } from "../../utils/constants";

const FadeInDownAnimation = keyframes`
  0% { 
    opacity: 0; 
    transform: translateY(-100%);
  }
  100% { 
    opacity: 1; 
    transform: translateY(0);
  }
`;

export const NavbarContainer = styled.div<{
  animated?: boolean;
}>`
  width: 100%;
  height: ${NAVBAR_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 48px;
  z-index: 99;
  background: ${({ theme }) => theme.dark.color};
  opacity: 0.7;

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.sm}px) {
      padding: 0 24px;
    }
  `}

  ${({ animated }) =>
    animated &&
    css`
      opacity: 0;
      animation: ${FadeInDownAnimation} 250ms ease-out 350ms;
      animation-fill-mode: forwards;
    `}
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 56px;
  margin-right: 32px;

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.md}px) {
      display: none;
    }
  `}
`;

export const MenuOption = styled.a<{ active?: boolean }>`
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme, active }) => (active ? theme.primary.color : "#fff")};

  &:hover {
    color: ${({ theme }) => theme.primary.color};
    transition: color 0.3s ease-out;
  }
`;

export const MenuIconContainer = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.md}px) {
      display: flex;
    }
  `}
`;

export const Bar = styled.div`
  width: 24px;
  height: 4px;
  background-color: white;
  margin: 3px 0;
`;

export const Logo = styled.img`
  display: flex;
  align-items: center;
  height: 48px;
  cursor: pointer;
  z-index: 99;
`;
