import React from "react";
import Carrousel from "./Carrousel/Carrousel";
import Features from "./Features/Features";
import Levels from "./Levels/Levels";
import { Section, ScrollSection } from "../../components";
import News from "../../components/News/News";
import { useHome } from "./useHome";
import { HomeSectionsEnum } from "./types";
import Admission from "../../components/Admission/Admission";
import { LevelsEnum } from "../../components/Admission/useForm";

const Home = () => {
  const {
    isMobile,
    loading,
    scrollSectionRef,
    actualOption,
    news,
    images,
    onSectionChanged,
    onAnimationEnd,
  } = useHome();

  return (
    <div className="home">
      <ScrollSection
        ref={scrollSectionRef}
        onSectionChanged={onSectionChanged}
        onAnimationEnd={onAnimationEnd}
      >
        <Section>
          <Carrousel
            isMobile={isMobile}
            startAnimation={!loading}
            slides={images}
          />
        </Section>
        <Section>
          <Levels isVisible={actualOption === HomeSectionsEnum.Levels} />
        </Section>
        <Section>
          <Features isVisible={actualOption === HomeSectionsEnum.Features} />
        </Section>
        {news?.length > 0 ? (
          <Section>
            <News
              dark
              news={news}
              isVisible={actualOption === HomeSectionsEnum.News}
            />
          </Section>
        ) : null}
        <Section>
          <Admission
            isVisible={actualOption === HomeSectionsEnum.Admission}
            level={LevelsEnum.ALL}
          />
        </Section>
      </ScrollSection>
    </div>
  );
};

export default Home;
