import { ThemeColors } from "@jcbruno96/react-ui-library";
import { saveAs } from "file-saver";
import styled from "styled-components";

type Props = {
  docName: string;
  docUrl: string;
  docType?: "doc" | "docx" | "pdf" | "jpg";
  color: ThemeColors;
};

const Download = ({ docName, docUrl, docType = "doc", color }: Props) => {
  return (
    <Card>
      <File>
        <DocName>{docName}</DocName>
      </File>
      <DownloadText
        color={color}
        onClick={(e) => downloadFile(docUrl, docName, docType)}
      >
        Descargar
      </DownloadText>
    </Card>
  );
};

const downloadFile = async (
  docUrl: string,
  docName: string,
  docType: string
) => {
  const res = await fetch(
    `https://institutoduayen.edu.ar/api/downloads/${docUrl}`
  );
  const blob = await res.blob();
  saveAs(blob, `${docName}.${docType}`);
};

export default Download;

const Card = styled.div`
  background-color: ${({ theme }) => theme.light.color};
  color: ${({ theme }) => theme.light.contrast};
  border-radius: 0px;
  padding: 8px 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  height: 60px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 100%;
  }
`;

const File = styled.div`
  flex: 1;
  margin-right: 24px;
`;

const DownloadText = styled.p<{ color: ThemeColors }>`
  color: ${({ theme, color }) => theme[color].color};
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const DocName = styled.p`
  font-size: 17px;
  margin: 0;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`;
