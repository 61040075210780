import styled, { css, keyframes } from "styled-components";
import { INITIAL_LOADING_TIME } from "../../utils/constants";
export const LOADING_FADE_OUT_TIME = 500;

const LoaderAnimationIn = keyframes`
  0% {
    height: 0;
  }
  100% {
    height: 100vh;
  }
`;

const LoaderAnimationOut = keyframes`
  0% {
    height: 100vh;
  }
  100% {
    height: 0;
  }
`;

const Spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const LoadingContainer = styled.div<{
  hide: boolean;
  background: boolean;
}>`
  position: fixed;
  background-color: ${({ background }) =>
    background ? "#000" : "transparent"};
  width: 100%;
  height: 100vh;
  z-index: 9999;
  left: 0;
  display: ${({ hide }) => (!hide ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

export const Loader = styled.div<{ show: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.primary.color};

  ${({ show }) =>
    show
      ? css`
          top: 0;
          bottom: auto;
          animation: ${LoaderAnimationIn} ${INITIAL_LOADING_TIME}ms
            cubic-bezier(0.79, 0.19, 0.18, 0.83) 100ms;
          animation-fill-mode: forwards;
        `
      : css`
          bottom: 0;
          top: auto;
          animation: ${LoaderAnimationOut} ${LOADING_FADE_OUT_TIME}ms
            cubic-bezier(0.79, 0.19, 0.18, 0.83);
          animation-fill-mode: forwards;
        `}
`;

export const Spinner = styled.div`
  border: 4px solid rgba(0,0,0,0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #000;
  opacity: 0;
  animation: ${FadeIn} 300ms ease-in-out 600ms, ${Spin} 1s linear infinite;
  animation-fill-mode: forwards;
`;
