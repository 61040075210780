import { useRef, useEffect } from "react";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { Title } from "../../../components/Title/Title";
import Carrousel from "../../../components/Carrousel/Carrousel";
import { Highlight } from "../../../components/Highlight/Highlight";
import Reveal from "../../../components/Reveal/Reveal";
import { slides } from "./slides";
import { FeaturesProps } from "./types";

const Features = ({ isVisible }: FeaturesProps) => {
  const firstRender = useRef<boolean>(true);

  const reveal = () => {
    var reveals = document.querySelectorAll("#features-container .reveal");
    for (var i = 0; i < reveals.length; i++) {
      reveals[i].classList.add("active");
    }
  };

  useEffect(() => {
    if (isVisible && firstRender.current) {
      reveal();
      firstRender.current = false;
    }
  }, [isVisible]);

  return (
    <PageContainer id="features-container">
      <Reveal delay={100}>
        <Title>
          ¿Por qué <Highlight>elegirnos?</Highlight>
        </Title>
      </Reveal>
      <Reveal delay={250}>
        <Carrousel slides={slides} />
      </Reveal>
    </PageContainer>
  );
};

export default Features;
