import Gallery from "../../components/Gallery/Gallery";

import Image1 from "../../assets/images/solidario1.webp";
import Image2 from "../../assets/images/solidario2.webp";
import Image3 from "../../assets/images/solidario3.webp";
import Image4 from "../../assets/images/solidario4.webp";
import Image5 from "../../assets/images/solidario5.webp";
import Image6 from "../../assets/images/solidario6.webp";
import Image7 from "../../assets/images/solidario7.webp";
import Image8 from "../../assets/images/solidario8.webp";
import Image9 from "../../assets/images/solidario9.webp";
import Image10 from "../../assets/images/solidario10.webp";
import Image11 from "../../assets/images/solidario11.webp";
import Image12 from "../../assets/images/solidario12.webp";
import Image13 from "../../assets/images/solidario13.webp";
import Image14 from "../../assets/images/solidario14.webp";
import Image15 from "../../assets/images/solidario15.webp";
import Image16 from "../../assets/images/solidario16.webp";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "../../redux/loading";

const Charity = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setLoading(false));
  }, [dispatch]);

  return (
    <div style={{ marginTop: 80 }}>
      <Gallery
        images={[
          Image8,
          Image2,
          Image3,
          Image4,
          Image5,
          Image10,
          Image7,
          Image1,
          Image9,
          Image6,
          Image11,
          Image12,
          Image13,
          Image14,
          Image15,
          Image16,
        ]}
      />
      <br />
    </div>
  );
};

export default Charity;
