import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AppRouter from "./routers/App";
import Loading from "./components/Loading/Loading";
import { RootState } from "./redux/store";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import { actions } from "./redux/loading";
import { MenuOptionEnum } from "./components/Navbar/types";

const options: { [k in string]: string } = {
  "/": MenuOptionEnum.Home,
  "/inicial": MenuOptionEnum.Kindergarten,
  "/primaria": MenuOptionEnum.Primary,
  "/secundaria": MenuOptionEnum.Secondary,
  "/nosotros": MenuOptionEnum.AboutUs,
  "/ingles": MenuOptionEnum.English,
  "/noticias/detalle": MenuOptionEnum.News,
  "/noticias": MenuOptionEnum.News,
  "/contacto": MenuOptionEnum.Contact,
  "/admision": MenuOptionEnum.Admission,
}

const App = () => {
  const { loading } = useSelector((state: RootState) => state.loading);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const goToTop = () => {
    const root = document.getElementById("root");
    if (root) {
      root.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const hasSectionScroll = useMemo(() => {
    const section = ["/", "/inicial", "/primaria", "/secundaria"].includes(
      pathname
    );
    return section || pathname.includes("/ingles");
  }, [pathname]);

  useEffect(() => {
    if(Object.keys(options).includes(pathname)){
      dispatch(actions.setActualOption(options[pathname] as MenuOptionEnum))
    } else {
      dispatch(actions.setActualOption(MenuOptionEnum.Home));
    }

    if (!hasSectionScroll) {
      const body = document.documentElement;
      const root = document.getElementById("root");
      if (body && root) {
        root.style.overflow = "auto";
        body.style.overflow = "auto";
      }
    }
    if (!pathname.includes("/ingles/")) goToTop();
  }, [pathname, hasSectionScroll, dispatch]);

  return (
    <div className="App">
      <Loading show={loading} />
      <Navbar animated={false} />
      <AppRouter />
    </div>
  );
};

export default App;
