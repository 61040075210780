import styled, { css } from "styled-components";

interface Props {
  placeholder?: string;
  name?: string;
  data?: any[];
  value?: string | number;
  errorText?: string;
  isError?: boolean;
  style?: React.CSSProperties;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  onBlur?: React.FocusEventHandler<HTMLSelectElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLSelectElement>;
}

const Select = (props: Props) => {
  const {
    style,
    errorText = "",
    data = [],
    placeholder = "Select Option",
    isError = false,
    ...others
  } = props;
  return (
    <Container>
      <StyledSelect
        isError={isError}
        autoComplete="off"
        empty={!!others.value}
        {...others}
      >
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {data?.map((option) => (
          <Option value={option.id} key={option.id}>
            {option.name}
          </Option>
        ))}
      </StyledSelect>
      <HelperText isError={isError}>{isError ? errorText : ""}</HelperText>
    </Container>
  );
};

export default Select;

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const StyledSelect = styled.select<{
  isError: boolean;
  empty: boolean;
}>`
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-color: transparent;
  border-color: ${({ isError }) => (isError ? "red" : "#777")};
  color: ${({ theme }) => theme.secondary.contrast};
  outline: none;
  margin: 0 8px;
  min-width: 200px;
  font-size: 18px;
  text-align: center;

  ${({ empty }) =>
    !empty &&
    css`
      color: #aaa;
    `}
`;

const HelperText = styled.p<{ isError: boolean }>`
  margin: 2px 4px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: ${({ theme, isError }) => (isError ? "red" : "#888")};
`;

const Option = styled.option`
  color: #2f2f2f;
`;
