import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Admission from "../../components/Admission/Admission";
import { LevelsEnum } from "../../components/Admission/useForm";
import { actions } from "../../redux/loading";

const AdmissionPage = () => {
  const dispatch = useDispatch();
  const firstRender = useRef<boolean>(true);
  
  const reveal = useCallback(() => {
    var reveals = document.querySelectorAll(`#admission-page .reveal`);
    for (var i = 0; i < reveals.length; i++) {
      reveals[i].classList.add("active");
    }
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      reveal();
      firstRender.current = false;
    }
  }, [reveal]);


  useEffect(() => {
    dispatch(actions.setLoading(false));
  }, [dispatch]);
  
  return (
    <Container id="admission-page">
      <Admission level={LevelsEnum.ALL} />
    </Container>
  );
};

export default AdmissionPage;

const Container = styled.div`
  padding-top: 60px;
  background-color: ${({ theme }) => theme.secondary.color};
`;
