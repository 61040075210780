import styled from "styled-components";
export const Title = styled.h2`
  position: relative;
  font-size: 3.4rem;
  width: 100%;
  margin: 0;
  cursor: default;
  text-align: left;
  line-height: 3.8rem;
  margin-top: 16px;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2.6rem;
    line-height: 3rem;
  }

  @media (max-width: 576px) {
    font-size: 1.9rem;
    line-height: 2.3rem;
  }
`;
