import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  background-color: #000;
  width: 100%;
  height: 100%;
`;

export const Borders = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0px 120px 100px rgba(0, 0, 0, 0.4);
  z-index: 99;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
`;

export const OverlayImage = styled(Image)<{ visible: boolean }>`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: ${({ visible }) => (visible ? "block" : "none")};
`;

export const logoStyles: React.CSSProperties = {
  position: "absolute",
  top: "48px",
  left: "48px",
};

export const BackIconContainer = styled.div`
  position: absolute;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  top: calc(50% - 32px);
  left: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    display: none;
  }
`;

export const NextIconContainer = styled(BackIconContainer)`
  left: auto;
  right: 60px;
`;

export const Logo = styled.img`
  display: flex;
  align-items: center;
  height: 56px;
  cursor: pointer;
  z-index: 99;
  position: absolute;
  top: 42px;
  left: 48px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  right: 44px;
  top: 84px;
  z-index: 99;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #2d2d2d;
  font-size: 20px;
  font-weight: 700;
  border-radius: 24px;
  cursor: pointer;
`;
