import Admission from "../../components/Admission/Admission";
import { LevelsEnum } from "../../components/Admission/useForm";
import LevelHeader from "../../components/LevelHeader/LevelHeader";
import PhotoAndText from "../../components/PhotoAndText/PhotoAndText";
import HeaderImage from "../../assets/images/_DUA5008.webp";
import Image1 from "../../assets/images/_DUA5002.webp";
import Image2 from "../../assets/images/_DUA5007.webp";
import Image3 from "../../assets/images/ingles_3.webp";
import Information from "./Information/Information";
import { Section, ScrollSection } from "../../components";
import News from "../../components/News/News";
import { useLevelController } from "../../hooks/useLevelController";
import { NewsCategory } from "../../interfaces/news";

const Secondary = () => {
  const {
    history,
    news,
    scrollSectionRef,
    actualOption,
    scrollToAdmission,
    onAnimationEnd,
    onSectionChanged,
  } = useLevelController("secondary-container", NewsCategory.Secondary);
  return (
    <div className="secondary-container" id="secondary-container">
      <ScrollSection
        ref={scrollSectionRef}
        onSectionChanged={onSectionChanged}
        onAnimationEnd={onAnimationEnd}
      >
        <Section>
          <LevelHeader
            title="Nivel Secundario"
            description='"Bachiller con orientación en Ciencias sociales y Humanidades" (R.M.321 y 1189/15)'
            imageUrl={HeaderImage}
            onClick={scrollToAdmission}
          />
        </Section>
        <Section>
          <PhotoAndText
            dark
            id="proyecto-educativo-secundaria"
            title="Proyecto Educativo"
            isVisible={actualOption === 1}
            description={[
              "Plan oficial de cinco años de duración con intensificación en Inglés y preparación para exámenes internacionales.",
              "Adquisición de herramientas en Investigación, escritura académica, debate y tesis, fundamentales para el desarrollo universitario",
            ]}
            imageUrl={Image1}
            tags={["universidad", "inglés"]}
            lettersHighlighted={8}
          />
        </Section>
        <Section>
          <PhotoAndText
            isReverse
            id="proyecto-deportivo-secundaria"
            isVisible={actualOption === 2}
            title="Proyecto Deportivo"
            description={[
              "Campo de deportes Club Cirse con traslados.",
              "Lunes y miércoles de 14.00 a 15.30 - 15.30 a 17.00hs 1ero, 2do y 3er año (primer turno). 4to y 5to año (segundo turno).",
              "Preparación física y práctica deportiva en agrupamientos mixtos. (Fútbol, Hockey, Voley, Handball, Atletismo y Softbol)",
              "Participación en torneos intercolegiales (CABA). Fútbol y Hockey.",
            ]}
            imageUrl={Image2}
            tags={["intercolegiales"]}
            lettersHighlighted={8}
          />
        </Section>
        <Section>
          <PhotoAndText
            title="Inglés"
            isVisible={actualOption === 3}
            dark
            id="ingles-secundaria"
            lettersHighlighted={10}
            description={[
              "Los estudiantes se preparan para Certificar sus conocimientos en dos instancias: First for Schools (Level B2) en 3º año, y C1 Advanced (CAE) en 5º año.",
              "Preparación para participar en las Olimpíadas de Traducción que organiza anualmente la Universidad de Belgrano",
            ]}
            imageUrl={Image3}
            buttonText="Conocer más"
            onClick={() => history.push("ingles/secundaria")}
          />
        </Section>
        <Section>
          <Information isVisible={actualOption === 4} />
        </Section>
        {news?.length > 0 ? (
          <Section>
            <News
              dark
              news={news}
              isVisible={actualOption === 5}
              section="secundaria"
            />
          </Section>
        ) : null}
        <Section>
          <Admission
            level={LevelsEnum.SECONDARY}
            isVisible={
              (actualOption === 6 && news?.length > 0) ||
              (actualOption === 5 && news?.length === 0)
            }
          />
        </Section>
      </ScrollSection>
    </div>
  );
};

export default Secondary;
