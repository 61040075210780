import styled from "styled-components";
import Reveal from "../Reveal/Reveal";

export const Logo = styled.img`
  display: flex;
  align-items: center;
  height: 56px;
  cursor: pointer;
  z-index: 99;
  position: absolute;
  top: 42px;
  left: 48px;
`;

export const Container = styled.div`
  min-height: 100vh;
  height: 850px;
  width: 100%;
  position: relative;
  padding: 0 10%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.55);
`;

export const Image = styled.div<{ imageUrl: string }>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: ${({ imageUrl }) => `url("${imageUrl}")`};
  background-size: cover;
  background-position: 50% 50%;
`;

export const Title = styled.h2`
  position: relative;
  font-size: 3.4rem;
  line-height: 4rem;
  margin: 0;
  color: #fff;
  text-align: left;
  z-index: 2;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    font-size: 2.6rem;
    line-height: 3rem;
  }

  @media (max-width: 576px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`;
export const Description = styled.p`
  position: relative;
  font-size: 1.5rem;
  line-height: 2rem;
  color: white;
  text-align: left;
  z-index: 2;
  width: 50%;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    line-height: 1.6rem;
    width: 70%;
  }

  @media (max-width: 576px) {
    font-size: 1rem;
    line-height: 1.4rem;
    width: 100%;
  }
`;

export const StyledReveal = styled(Reveal)`
  z-index: 2;
`