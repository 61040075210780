import React, { useCallback, useEffect } from "react";
import { Col, Icon, Row, Typography } from "@jcbruno96/react-ui-library";
import { useDispatch } from "react-redux";
import styled, { useTheme } from "styled-components";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { Title } from "../../components/Title/Title";
import { actions } from "../../redux/loading";
import { delayMs } from "../../utils/delay";

export const Contact = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const initialRender = useCallback(async () => {
    await delayMs(1000);
    dispatch(actions.setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    initialRender();
  }, [initialRender]);

  return (
    <PageContainer>
      <HeaderRow style={{ marginTop: 64 }}>
        <Col xs={12}>
          <Title>Contacto</Title>
        </Col>
      </HeaderRow>
      <Row style={{ marginTop: "32px" }}>
        <Col
          sm={12}
          lg={6}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="h5"
            fontWeight="700"
            style={{ color: theme.secondary.contrast }}
          >
            Nivel Inicial
          </Typography>
          <IconContainer>
            <Icon
              icon="map-marker-alt"
              color={theme.primary.color}
              style={{ marginRight: "16px" }}
            />
            <Typography
              variant="h6"
              style={{ color: theme.secondary.contrast }}
            >
              4522-9292 / 4524-2694
            </Typography>
          </IconContainer>
          <IconContainer>
            <Icon
              icon="envelope"
              color={theme.primary.color}
              style={{ marginRight: "16px" }}
            />
            <Link href="mailto:jardin@institutoduayen.edu.ar">
              jardin@institutoduayen.edu.ar
            </Link>
          </IconContainer>

          <br />

          <Typography
            variant="h5"
            fontWeight="700"
            style={{ color: theme.secondary.contrast }}
          >
            Nivel Primario
          </Typography>
          <IconContainer>
            <Icon
              icon="map-marker-alt"
              color={theme.primary.color}
              style={{ marginRight: "16px" }}
            />
            <Typography
              variant="h6"
              style={{ color: theme.secondary.contrast }}
            >
              4521-6023 / 4524-1956
            </Typography>
          </IconContainer>
          <IconContainer>
            <Icon
              icon="envelope"
              color={theme.primary.color}
              style={{ marginRight: "16px" }}
            />
            <Link href="mailto:primaria@institutoduayen.edu.ar">
              primaria@institutoduayen.edu.ar
            </Link>
          </IconContainer>

          <br />

          <Typography
            variant="h5"
            fontWeight="700"
            style={{ color: theme.secondary.contrast }}
          >
            Nivel Medio
          </Typography>
          <IconContainer>
            <Icon
              icon="map-marker-alt"
              color={theme.primary.color}
              style={{ marginRight: "16px" }}
            />
            <Typography
              variant="h6"
              style={{ color: theme.secondary.contrast }}
            >
              4521-6023 / 4524-1956
            </Typography>
          </IconContainer>
          <IconContainer>
            <Icon
              icon="envelope"
              color={theme.primary.color}
              style={{ marginRight: "16px" }}
            />
            <Link href="mailto:secundaria@institutoduayen.edu.ar">
              secundaria@institutoduayen.edu.ar
            </Link>
          </IconContainer>
          <br />
          <Typography
            variant="h5"
            fontWeight="700"
            style={{ color: theme.secondary.contrast }}
          >
            Horarios de atención
          </Typography>
          <IconContainer>
            <Icon
              icon="clock"
              type="regular"
              color={theme.primary.color}
              style={{ marginRight: "16px" }}
            />
            Lunes a viernes de 08:00 a 17:00 hs.
          </IconContainer>
          <br />
          <Typography
            variant="h5"
            fontWeight="700"
            style={{ color: theme.secondary.contrast }}
          >
            Correos
          </Typography>
          <IconContainer>
            <Icon
              icon="envelope"
              color={theme.primary.color}
              style={{ marginRight: "16px" }}
            />
            <Link href="mailto:info@institutoduayen.edu.ar">
              info@institutoduayen.edu.ar
            </Link>
          </IconContainer>
          <IconContainer>
            <Icon
              icon="envelope"
              color={theme.primary.color}
              style={{ marginRight: "16px" }}
            />
            <Link href="mailto:administracion@institutoduayen">
              administracion@institutoduayen
            </Link>
          </IconContainer>
        </Col>
        <Col
          sm={12}
          lg={6}
          style={{ alignItems: "flex-start", flexDirection: "column" }}
        >
          <Typography
            variant="h5"
            fontWeight="700"
            style={{ color: theme.secondary.contrast }}
          >
            Ubicación
          </Typography>
          <IconContainer>
            <Icon
              icon="map-marker-alt"
              color={theme.primary.color}
              style={{ marginRight: "16px" }}
            />
            <Typography
              variant="h6"
              style={{ color: theme.secondary.contrast }}
            >
              Ballivian 2162 - 2230, Villa Urquiza, C.A.B.A.
            </Typography>
          </IconContainer>
          <IconContainer>
            <Icon
              icon="map-marker-alt"
              color={theme.primary.color}
              style={{ marginRight: "16px" }}
            />
            <Typography
              variant="h6"
              style={{ color: theme.secondary.contrast }}
            >
              <>
                <strong>Colegio cercano a:</strong> Belgrano, Villa Urquiza,
                Colegiales, Chacarita, Villa Crespo, Nuñez, Villa Pueyrredón,
                Coghlan, Saavedra, Agronomía (Buenos Aires)
              </>
            </Typography>
          </IconContainer>

          <Map
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1642.503095511906!2d-58.47531852929907!3d-34.57870990110947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb671fcdd837f%3A0x40b542d92ec602b1!2sInstituto%20de%20Educaci%C3%B3n%20Duay%C3%A9n%20A-990!5e0!3m2!1ses-419!2sar!4v1629658268884!5m2!1ses-419!2sar"
            width="600"
            height="400"
            style={{ border: "0", marginTop: 32 }}
            allowFullScreen
            loading="lazy"
          />
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Contact;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 10px 32px;
  gap: 8px;
`;

const Map = styled.iframe`
  padding: 16px;
  background: ${({ theme }) => theme.dark.color};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 16px;
  width: 100%;
  max-width: 600px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: 32px;
  }
`;

const HeaderRow = styled(Row)`
  &::after {
    content: "";
    height: 2px;
    width: 35%;
    background-color: #4f4f4f;
  }
`;

const Link = styled.a`
  color: ${({ theme }) => theme.secondary.contrast};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.primary.color};
  }
`;
