import styled, { css } from "styled-components";

const Black = css`
  color: white;
  background: black;
`;

const Green = css`
  color: white;
  background: ${({ theme }) => theme.primary.color};
`;

const Disabled = css`
  color: #999;
  background: #5f5f5f;
`;

export const Button = styled.button<{ inverted?: boolean; disabled?: boolean }>`
  position: relative;
  font-size: 1rem;
  padding: 0.6rem 1.4rem;
  display: block;
  outline: none;
  border: none;
  z-index: 2;
  font-weight: 400;
  font-family: "Nunito";
  cursor: pointer;
  border-radius: 20px;
  transition: color 0.3s, background 0.3s;

  ${({ inverted, disabled }) =>
    disabled ? Disabled : inverted ? Green : Black};

  &:hover {
    ${({ inverted, disabled }) =>
      disabled ? Disabled : !inverted ? Green : Black};
  }
`;
