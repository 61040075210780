import { useCallback, useEffect, useRef } from "react";
import { Col, Row, useResponsive } from "@jcbruno96/react-ui-library";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { Subtitle } from "../../../components/Subtitle/Subtitle";
import Time from "../../../components/Time/Time";
import Reveal from "../../../components/Reveal/Reveal";
import { StyledReveal } from "./styles";
import DocumentContainer from "../../../components/DocumentContainer/DocumentContainer";
import { documents } from "./documents";

type InformationProps = {
  isVisible: boolean;
};

const Information = ({ isVisible }: InformationProps) => {
  const { isMobile } = useResponsive(576);
  const firstRender = useRef<boolean>(true);

  const reveal = useCallback(() => {
    var reveals = document.querySelectorAll(`#secondary-information .reveal`);
    for (var i = 0; i < reveals.length; i++) {
      reveals[i].classList.add("active");
    }
  }, []);

  useEffect(() => {
    if (isVisible && firstRender.current) {
      reveal();
      firstRender.current = false;
    }
  }, [isVisible, reveal]);

  return (
    <PageContainer id="secondary-information">
      <Row>
        <Col
          xs={12}
          md={6}
          style={{ flexDirection: "column", padding: isMobile ? 12 : 32 }}
        >
          <Reveal delay={100}>
            <Subtitle style={{ marginBottom: 32 }}>Documentación</Subtitle>
          </Reveal>
          <Reveal delay={200}>
            <DocumentContainer files={documents} />
          </Reveal>
        </Col>
        <Col
          xs={12}
          md={6}
          style={{ flexDirection: "column", padding: isMobile ? 12 : 32 }}
        >
          <Reveal delay={100}>
            <Subtitle>Horarios</Subtitle>
          </Reveal>
          <Row>
            <Col xs={12} sm={6}>
              <StyledReveal delay={200}>
                <Time color="primary">
                  <>
                    <b>7.40 a 13.25 hs</b> <br />
                    (con extensión extraprogramática específica por curso)
                  </>
                </Time>
              </StyledReveal>
            </Col>
          </Row>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Information;
