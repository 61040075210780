import { ImagesLoaded } from "../interfaces/images";

const imagesLoaded = require("imagesloaded");

export const onImagesLoaded = (elem: Element) => {
  const imgLoad = imagesLoaded(elem);

  return new Promise((resolve, reject) => {
    imgLoad.on("done", (instance: ImagesLoaded) => {
      resolve(instance?.images?.length);
    });

    imgLoad.on("fail", (instance: any) => {
      reject(instance);
    });
  });
};
