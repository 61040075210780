import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MenuOptionEnum } from '../../components/Navbar/types';
import { LoadingState } from './types'

const initialState: LoadingState = {
  loading: true,
  actualOption: MenuOptionEnum.Home
}

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading: (state: LoadingState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setActualOption: (state: LoadingState, action: PayloadAction<MenuOptionEnum>) => {
      state.actualOption = action.payload;
    }
  },
})

export const actions = loadingSlice.actions

export default loadingSlice.reducer