import { FileType } from "../../../components/DocumentContainer/types";

export const documents: FileType[] = [
  {
    url: "I_Ficha de datos de Alumno 2022.docx",
    label: "Ficha de datos de Alumno",
  },
  {
    url: "I_Ficha Deportiva 2022.doc",
    label: "Ficha de antecedentes de salud",
  },
  {
    url: "I_AUTORIZACIONES SALA DE 5 CAMPO 2021.doc",
    label: "Autorización campo - Sala de 5 años",
  },
  { url: "I_Ficha de Inscripcion 2022.doc", label: "Ficha de Inscripción" },
  {
    url: "I_LISTAS MATERIALES 2022 - SALA 2.doc",
    label: "Listado de materiales - Sala de 2 años",
  },
  {
    url: "I_LISTAS MATERIALES 2022 - SALA 3.doc",
    label: "Listado de materiales - Sala de 3 años",
  },
  {
    url: "I_LISTAS MATERIALES 2022 - SALA 4.doc",
    label: "Listado de materiales - Sala de 4 años",
  },
  {
    url: "I_LISTAS MATERIALES 2022 - SALA 5.doc",
    label: "Listado de materiales - Sala de 5 años",
  },
];
