import { Col, Row, useResponsive } from "@jcbruno96/react-ui-library";
import { useCallback, useEffect, useRef } from "react";
import DocumentContainer from "../../../components/DocumentContainer/DocumentContainer";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import Reveal from "../../../components/Reveal/Reveal";
import { Subtitle } from "../../../components/Subtitle/Subtitle";
import Time from "../../../components/Time/Time";
import { StyledReveal } from "./styles";
import { documents } from "./documents";

type InformationProps = {
  isVisible: boolean;
};

const Information = ({ isVisible }: InformationProps) => {
  const { isMobile } = useResponsive(576);
  const firstRender = useRef<boolean>(true);

  const reveal = useCallback(() => {
    var reveals = document.querySelectorAll(
      `#kindergarten-information .reveal`
    );
    for (var i = 0; i < reveals.length; i++) {
      reveals[i].classList.add("active");
    }
  }, []);

  useEffect(() => {
    if (isVisible && firstRender.current) {
      reveal();
      firstRender.current = false;
    }
  }, [isVisible, reveal]);

  return (
    <PageContainer id="kindergarten-information">
      <Row>
        <Col
          xs={12}
          md={6}
          style={{ flexDirection: "column", padding: isMobile ? 12 : 32 }}
        >
          <Reveal delay={100}>
            <Subtitle style={{ marginBottom: 32 }}>Documentación</Subtitle>
          </Reveal>
          <Reveal delay={200}>
            <DocumentContainer files={documents} />
          </Reveal>
        </Col>
        <Col
          xs={12}
          md={6}
          style={{ flexDirection: "column", padding: isMobile ? 12 : 32 }}
        >
          <Reveal delay={100}>
            <Subtitle>Horarios</Subtitle>
          </Reveal>
          <Row>
            <Col xs={12} sm={6} style={{ flexDirection: "column" }}>
              <StyledReveal delay={200}>
                <Time color="primary">
                  <>
                    Turno Mañana <br />
                    <b>08:15 a 12:15 hs</b> <br />
                    <br />
                    Horario de almuerzo
                    <br />
                    <b>12:15 a 13:00 hs</b>
                  </>
                </Time>
              </StyledReveal>
              <br />
              <StyledReveal delay={300}>
                <Time color="primary">
                  <>
                    Extensión horaria general <br />
                    <b>7.30 a 18.00 hs</b>
                  </>
                </Time>
              </StyledReveal>
            </Col>
            <Col xs={12} sm={6} style={{ flexDirection: "column" }}>
              <StyledReveal delay={400}>
                <Time color="success">
                  <>
                    Extensión turno tarde <br />
                    <br />
                    Sala de 2, 3 y 4 años
                    <br />
                    <b>13.30 a 16.45 hs</b> <br />
                    <br />
                    Sala de 5 años
                    <br />
                    <b>13.15 a 16.30 hs</b>
                  </>
                </Time>
              </StyledReveal>
            </Col>
          </Row>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Information;
