import Admission from "../../components/Admission/Admission";
import { LevelsEnum } from "../../components/Admission/useForm";
import LevelHeader from "../../components/LevelHeader/LevelHeader";
import PhotoAndText from "../../components/PhotoAndText/PhotoAndText";
// import HeaderImage from "../../assets/images/_DUA6521.jpg";
import HeaderImage from "../../assets/images/Portada inicial.webp";
import Image1 from "../../assets/images/_DUA6527.webp";
import Image2 from "../../assets/images/teaching_swimming.webp";
import Image3 from "../../assets/images/inicial_ingles.webp";
import Information from "./Information/Information";

import { Section, ScrollSection } from "../../components";
import News from "../../components/News/News";
import { useLevelController } from "../../hooks/useLevelController";
import { NewsCategory } from "../../interfaces/news";

const Kindergarten = () => {
  const {
    history,
    news,
    scrollSectionRef,
    actualOption,
    scrollToAdmission,
    onAnimationEnd,
    onSectionChanged,
  } = useLevelController("kindergarten-container", NewsCategory.Kindergarten);
  return (
    <div className="kindergarten-container" id="kindergarten-container">
      <ScrollSection
        ref={scrollSectionRef}
        onSectionChanged={onSectionChanged}
        onAnimationEnd={onAnimationEnd}
      >
        <Section>
          <LevelHeader
            title="Nivel Inicial"
            description="“Il Pulcino Ballerino” cuenta con salas de dos, tres, cuatro y cinco
        años. Por la mañana, el espacio curricular y, por la tarde, una
        extensión con propuestas en formato taller."
            imageUrl={HeaderImage}
            onClick={scrollToAdmission}
          />
        </Section>
        <Section>
          <PhotoAndText
            id="proyecto-educativo-jardin"
            title="Proyecto Educativo"
            tags={["moderno", "inglés"]}
            dark
            isVisible={actualOption === 1}
            description={[
              "Propuesta centrada en “Proyectos” con soporte curricular, basados en el interés de los/las estudiantes.",
              "Introducción a la lengua extranjera (Inglés), desde sala de dos años, en articulación con el plan de intensificación en idioma de nivel primario.",
            ]}
            imageUrl={Image1}
            lettersHighlighted={8}
          />
        </Section>
        <Section>
          <PhotoAndText
            id="proyecto-deportivo-jardin"
            isReverse
            isVisible={actualOption === 2}
            title="Proyecto Deportivo"
            description={[
              "Actividades deportivas desde sala de dos favoreciendo el desarrollo integral de los/las estudiantes.",
              "Natación (opcional) desde sala de dos años en nuestro Instituto.",
              "Campo de deportes Club Cirse. Turno tarde para sala de cinco años",
              "Pernoctada y Campamento para sala de 5 años con sus profesores.",
            ]}
            tags={["natatorio", "campamento"]}
            imageUrl={Image2}
            lettersHighlighted={8}
          />
        </Section>
        <Section>
          <PhotoAndText
            id="ingles-jardin"
            title="Inglés"
            dark
            isVisible={actualOption === 3}
            lettersHighlighted={10}
            description={[
              "Comenzamos con estímulos en inglés desde el Nivel Inicial – en las Salas de 2, 3, 4 y 5 años.",
              "Trabajamos de forma lúdica, natural y gradual, a través de juegos, canciones, dramatizaciones, títeres, y cuentos.",
            ]}
            imageUrl={Image3}
            buttonText="Conocer más"
            onClick={() => history.push("ingles/inicial")}
          />
        </Section>
        <Section>
          <Information isVisible={actualOption === 4} />
        </Section>
        {news?.length > 0 ? (
          <Section>
            <News
              dark
              news={news}
              isVisible={actualOption === 5}
              section="inicial"
            />
          </Section>
        ) : null}
        <Section>
          <Admission
            level={LevelsEnum.KINDERGARTEN}
            isVisible={
              (actualOption === 6 && news?.length > 0) ||
              (actualOption === 5 && news?.length === 0)
            }
          />
        </Section>
      </ScrollSection>
    </div>
  );
};

export default Kindergarten;
