import styled, { css } from "styled-components";

export const NewsContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  ${({ theme: { breakpoints } }) => css`
    @media (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg}px) {
      flex-direction: row;
      height: 100%;
    }
  `}
`;

export const NewsImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: 220px;

  ${({ theme: { breakpoints } }) => css`
    @media (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg}px) {
      min-height: 100%;
      height: auto;
      width: 200px;
      min-width: 200px;
    }
  `}
`;

export const NewsContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;

  ${({ theme: { breakpoints } }) => css`
    @media (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg}px) {
      padding: 8px 16px;
      gap: 6px;
    }
  `}
`;

export const NewsDate = styled.p`
  margin: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.primary.color};
`;

export const NewsTitle = styled.h5`
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;

  ${({ theme: { breakpoints } }) => css`
    @media (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg}px) {
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      height: 30px;
    }
  `}
`;

export const NewsDescription = styled.p`
  line-height: 20px;
  font-size: 15px;
  color: #828893;
  max-height: 60px;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const SeeMore = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
  color: ${({ theme }) => theme.primary.color};
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    letter-spacing: 1px;
  }
`;
