import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Title } from "../Title/Title";
import Input from "../Input/Input";
import Select from "../Select/Select";

import { LevelsEnum, useForm } from "./useForm";
import { Highlight } from "../Highlight/Highlight";
import { Button } from "../Button/Button";
import Reveal from "../Reveal/Reveal";
import Footer from "../Footer/Footer";

interface Props {
  id?: string;
  level: LevelsEnum;
  dark?: boolean;
  isVisible?: boolean;
}

const Admission = ({
  id = "admission-component",
  level = LevelsEnum.ALL,
  dark = false,
  isVisible,
}: Props) => {
  const firstRender = useRef<boolean>(true);
  const [sending, setSending] = useState(false);
  const [messageLevel, showMessageLevel] = useState<
    "none" | "success" | "error"
  >("none");
  const { form, levels, courses } = useForm(
    level,
    (status: "sending" | "error" | "success") => {
      if (status === "sending") setSending(true);
      else {
        setSending(false);
        showMessageLevel(status);
      }
    }
  );

  const selectLevel = () => {
    if (level !== LevelsEnum.ALL) return "";

    return (
      <>
        {" "}
        el nivel
        <Select
          placeholder="elegir nivel"
          name="level"
          value={form.values.level}
          data={levels}
          onChange={form.handleChange}
          errorText={form.errors?.level}
          isError={!!form.errors?.level && !!form.touched?.level}
        />
        en el curso
      </>
    );
  };

  const reveal = useCallback(() => {
    var reveals = document.querySelectorAll(`#${id} .reveal`);
    for (var i = 0; i < reveals.length; i++) {
      reveals[i].classList.add("active");
    }
  }, [id]);

  useEffect(() => {
    if (isVisible && firstRender.current) {
      reveal();
      firstRender.current = false;
    }
  }, [isVisible, reveal]);

  return (
    <Container id={id} dark={dark}>
      <Form onSubmit={form.handleSubmit}>
        <Reveal delay={100}>
          <Title>
            <Highlight>Programar</Highlight> una entrevista
          </Title>
        </Reveal>

        <Reveal delay={200}>
          <>
            <Text>
              Hola, mi nombre es
              <Input
                placeholder="nombre completo"
                name="fullname"
                value={form.values.fullname}
                onChange={form.handleChange}
                errorText={form.errors?.fullname}
                isError={!!form.errors?.fullname && !!form.touched?.fullname}
              />
              . {"  "}Estoy buscando vacante para mi hijo/a para
              {selectLevel()}
              <Select
                placeholder="elegir curso"
                name="course"
                value={form.values.course}
                data={courses}
                onChange={form.handleChange}
                errorText={form.errors?.course}
                isError={!!form.errors?.course && !!form.touched?.course}
              />
              . Me pueden contactar a través de mi correo electrónico
              <Input
                placeholder="correo@direccion.com"
                name="email"
                type="email"
                value={form.values.email}
                onChange={form.handleChange}
                errorText={form.errors?.email}
                isError={!!form.errors?.email && !!form.touched?.email}
              />
              o de mi número de teléfono
              <Input
                placeholder="11 1234 5678"
                name="phone"
                type="tel"
                value={form.values.phone}
                onChange={form.handleChange}
                errorText={form.errors?.phone}
                isError={!!form.errors?.phone && !!form.touched?.phone}
              />
              .
            </Text>

            <br />
            <Text>Quisiera recibir más información sobre</Text>
            <Textarea
              placeholder="escribir mensaje"
              name="extraMessage"
              value={form.values.extraMessage}
              onChange={form.handleChange}
            />
          </>
        </Reveal>
        <Reveal delay={300} offset={40}>
          <Button
            style={{ marginTop: 32 }}
            type="submit"
            inverted
            disabled={sending}
          >
            {sending ? "Enviando..." : "Enviar"}
          </Button>
        </Reveal>
        {messageLevel !== "none" && (
          <MessageContainer isError={messageLevel === "error"}>
            {messageLevel === "error"
              ? "Ha ocurrido un error en el servidor, inténtelo nuevamente en unos minutos"
              : "La solicitud de entrevista fue enviada correctamente. Un representante se pondrá en contacto a la brevedad."}
          </MessageContainer>
        )}
      </Form>
      <Footer />
    </Container>
  );
};

export default Admission;

const Container = styled.div<{ dark: boolean }>`
  min-height: calc(100vh - 60px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  gap: 32px;
  background-color: ${({ theme, dark }) =>
    dark ? theme.secondary.color : theme.light.color};
  color: ${({ theme, dark }) =>
    dark ? theme.secondary.contrast : theme.light.contrast};
`;

const Form = styled.form`
  width: 80%;
  margin: auto;
  max-width: 1200px;
`;

const Textarea = styled.textarea`
  resize: none;
  border: none;
  border-bottom: 1px solid #777;
  color: ${({ theme }) => theme.secondary.contrast};
  background-color: transparent;
  outline: none;
  margin: 0;
  min-width: 200px;
  font-size: 18px;
  font-family: "Nunito";
  //text-align: center;
  width: 100%;
`;

const Text = styled.div`
  margin: 0;
  font-size: 1.2rem;
  line-height: 3rem;
`;

const MessageContainer = styled.div<{ isError: boolean }>`
  display: flex;
  padding: 16px;
  width: 80%;
  margin: auto;
  margin-top: 32px;
  max-width: 1200px;
  font-size: 1.1rem;
  background-color: ${({ theme }) => theme.light.color};
  color: ${({ theme }) => theme.light.contrast};
  border: 2px dashed
    ${({ theme, isError }) =>
      isError ? theme.danger.color : theme.success.color};
`;
