import styled from "styled-components";

export const RevealContainer = styled.div<{ offset: number; delay?: number; isAbsolute?: boolean }>`
  position: relative;
  transform: ${({ offset }) => `translateY(${offset}px)`};
  opacity: 0;
  transition: all 1s ease;
  width: 100%;
  transition-delay: ${({ delay }) => delay || 0}ms;

  &.active {
    transform: translateY(0);
    opacity: 1;
  }
`;
