import styled, { css } from "styled-components";

export const ScrollContainer = styled.div<{
  transitionTime: number;
  width: number;
  height: number;
}>`
  position: relative;
  overflow: hidden;
  font-size: 0px;
  letter-spacing: 0px;
  word-spacing: 0px;

  ${({ width, height, transitionTime, theme }) => css`
    width: ${width}px;
    height: ${height}px;
    transition: transform ${transitionTime || 1000}ms cubic-bezier(.79,.19,.18,.83);

    @media (max-width: ${theme.breakpoints.md}px) {
      height: fit-content;
    }
  `}
`;

export const Section = styled.div<{ width?: number; height?: number, fullSize?: boolean }>`
  position: relative;
  overflow: hidden;
  display: inline-block;
  box-sizing: border-box;
  cursor: default;
  margin: 0;
  padding: 0;

  ${({ width, height, theme, fullSize }) => css`
    width: ${width}px;
    height: ${height}px;

    @media (max-width: ${theme.breakpoints.md}px) {
      min-height: fit-content;
      height: ${fullSize ? "100vh" : "fit-content"};
    }
  `}

  
`;
