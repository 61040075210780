import { useNews } from "./useNews";
import {
  StyledPageContainer,
  Filter,
  FilterContainer,
  NoResultsContainer,
  NoResultsText,
  NoResultsImage,
} from "./styles";
import NewsGrid from "../../components/News/News";
import { useCallback } from "react";
import { categories } from "./categories";
import NewsImage from "../../assets/images/news.png";

const NewsDetail = () => {
  const { category, news, onChangeCategory } = useNews();

  const renderFilter = useCallback(() => {
    return categories.map((cat) => {
      return (
        <Filter
          isActive={category === cat.id}
          onClick={() => onChangeCategory(cat.id)}
        >
          {cat.label}
        </Filter>
      );
    });
  }, [category, onChangeCategory]);

  const renderContent = useCallback(() => {
    if (news?.length === 0) {
      return (
        <NoResultsContainer>
          <NoResultsImage src={NewsImage} alt="no-news" />
          <NoResultsText>
            Aún no tenemos noticias para mostrarte
          </NoResultsText>
        </NoResultsContainer>
      );
    }

    return (
      <NewsGrid isVisible showTitle={false} news={news} showViewAll={false} />
    );
  }, [news]);

  return (
    <StyledPageContainer id="news-page">
      <FilterContainer>{renderFilter()}</FilterContainer>
      {renderContent()}
    </StyledPageContainer>
  );
};

export default NewsDetail;
