import { Switch, Route } from "react-router-dom";
// Pages
import Home from "../pages/Home/Home";
import Kindergarten from "../pages/Kindergarten/Kindergarten";
import Primary from "../pages/Primary/Primary";
import Secondary from "../pages/Secondary/Secondary";
import Contact from "../pages/Contact/Contact";
import English from "../pages/English/English";
import AdmissionPage from "../pages/Admission/Admission";
import AboutUs from "../pages/AboutUs/AboutUs";
import GreenSchool from "../pages/GreenSchool/GreenSchool";
import Charity from "../pages/Charity/Charity";
import NewsDetail from "../pages/NewsDetail/NewsDetail";
import News from "../pages/News/News";

export const AppRouter = () => {
  return (
    <Switch>
      <Route path="/nosotros">
        <AboutUs />
      </Route>
      <Route path="/inicial">
        <Kindergarten />
      </Route>
      <Route path="/primaria">
        <Primary />
      </Route>
      <Route path="/secundaria">
        <Secondary />
      </Route>
      <Route path={["/ingles", "/ingles/:level"]} exact>
        <English />
      </Route>
      <Route path="/contacto">
        <Contact />
      </Route>
      <Route path="/admision">
        <AdmissionPage />
      </Route>
      <Route exact path={["/noticias", "/noticias/:section"]}>
        <News />
      </Route>
      <Route path="/noticias/detalle/:id">
        <NewsDetail />
      </Route>
      <Route path="/escuela-verde">
        <GreenSchool />
      </Route>
      <Route path="/proyectos-solidarios">
        <Charity />
      </Route>
      <Route path="/:section">
        <Home />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
};

export default AppRouter;
