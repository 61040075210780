import { useState, useRef, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { Grid, Row, Col, Icon, Typography } from "@jcbruno96/react-ui-library";
import { Button } from "../Button/Button";
import { useHistory } from "react-router";
import Hammer from "hammerjs";
import { CardShadow } from "../../styles";

export type SlideType = {
  title: string;
  description: string;
  imageUrl: string;
  redirectUrl?: string;
  external?: boolean;
  buttonText?: string;
  logo?: string;
};

type Props = {
  slides: SlideType[];
};

const Carrousel = ({ slides }: Props) => {
  const theme = useTheme();
  const [active, setActive] = useState(0);
  const slideRef = useRef<HTMLDivElement | null>(null);
  const hammerRef = useRef<any>(null);

  const goPrev = () => {
    setActive((prev) => {
      if (prev === 0) return slides.length - 1;
      else return prev - 1;
    });
  };

  const goNext = () => {
    setActive((prev) => {
      if (prev === slides.length - 1) return 0;
      else return prev + 1;
    });
  };

  useEffect(() => {
    if (slideRef?.current) {
      hammerRef.current = new Hammer(slideRef.current);
      hammerRef.current.on("swipeleft", goNext); // remove ()
      hammerRef.current.on("swiperight", goPrev); // remove ()
    }

    return () => {
      if (hammerRef?.current) {
        hammerRef.current.remove("swipeleft", goNext); // remove ()
        hammerRef.current.remove("swiperight", goPrev); // remove ()
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideRef]);

  return (
    <>
      <Card ref={slideRef}>
        <BackIcon
          size={28}
          color={theme.primary.color}
          icon="chevron-left"
          onClick={goPrev}
        />
        <NextIcon
          size={28}
          color={theme.primary.color}
          icon="chevron-right"
          onClick={goNext}
        />
        {slides.map((slide, index) => (
          <Slide key={index} slide={slide} visible={index === active} />
        ))}
      </Card>
      <BulletsContainer>
        {slides.map((_, index) => (
          <Bullet key={index} active={index === active} />
        ))}
      </BulletsContainer>
    </>
  );
};

export default Carrousel;

type SlideProps = {
  slide: SlideType;
  visible: boolean;
};

const Slide = ({ slide, visible }: SlideProps) => {
  const theme = useTheme();
  const history = useHistory();
  return (
    <StyledGrid visible={visible}>
      <Row>
        <Col xs={12} lg={6}>
          <Image src={slide.imageUrl} alt="carrousel-image" />
        </Col>
        <Col xs={12} lg={6}>
          <TextContainer>
            <Typography
              variant="h3"
              style={{ color: theme.secondary.contrast }}
            >
              {slide.title}
            </Typography>
            <Typography
              variant="h6"
              style={{ color: theme.secondary.contrast }}
            >
              {slide.description}
            </Typography>
            <CtaContainer>
              {slide?.redirectUrl ? (
                <Button
                  inverted
                  onClick={() =>
                    slide?.external
                      ? window.open(slide?.redirectUrl, "_blank")
                      : history.push(slide?.redirectUrl || "/")
                  }
                  style={{ maxHeight: "fit-content" }}
                >
                  {slide?.buttonText || "Leer más"}
                </Button>
              ) : (
                <></>
              )}
              {slide?.logo ? <Logo src={slide.logo} alt="Logo" /> : <></>}
            </CtaContainer>
          </TextContainer>
        </Col>
      </Row>
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)<{ visible?: boolean }>`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  animation-name: fadeIn;
  animation-duration: 2.4s;
  animation-iteration-count: 1;

  & > div > div {
    padding: 0;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

const Card = styled.div`
  position: relative;
  ${CardShadow}
  background-color: transparent;
  color: ${({ theme }) => theme.dark.contrast};
  //padding: 16px 60px 60px 60px;
  padding: 0;
  background-color: #f9f9f9;
`;

const BackIcon = styled(Icon)`
  position: absolute;
  top: calc(50% - 12px);
  left: 24px;
  z-index: 2;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    display: none;
  }
`;

const NextIcon = styled(BackIcon)`
  left: auto;
  right: 24px;
`;

const Image = styled.img`
  width: 100%;
  height: 50vh;
  max-height: 50vh;
  margin: auto;
  object-fit: cover;

  animation-name: move;
  animation-duration: 1s;
  animation-iteration-count: 1;

  @keyframes move {
    0% {
      transform: translateX(-64px);
    }

    100% {
      transform: translateX(0px);
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    width: 100%;
    height: 260px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  padding: 16px 64px 16px 48px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  height: 100%;
  width: 100%;

  animation-name: moveUp;
  animation-duration: 1s;
  animation-iteration-count: 1;

  @keyframes moveUp {
    0% {
      transform: translateY(-64px);
    }

    100% {
      transform: translateY(0px);
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    padding: 16px;
    min-height: 220px;
    gap: 16px;
    justify-content: flex-start;

    & > h6 {
      font-size: 14px;
    }

    & > h3 {
      font-size: 24px;
    }
  }
`;

const BulletsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 2;
  gap: 8px;
  margin-top: 32px;
`;

const Bullet = styled.div<{ active: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${({ active, theme }) =>
    active ? theme.primary.color : "#777"};
  transition: background-color 0.3s;
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Logo = styled.img`
  height: 80px;
`;
