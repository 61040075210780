import styled from "styled-components";

import Image1 from "../../../assets/images/colonia.webp";
import Image2 from "../../../assets/images/jardin.webp";
import Image3 from "../../../assets/images/1992.webp";
import Image4 from "../../../assets/images/edificio_antiguo.webp";
import Image5 from "../../../assets/images/back3.webp";

const TimelineContainer = () => {
  return (
    <Timeline>
      {/* <TitleContainer>
        <Title>Nuestra historia</Title>
      </TitleContainer> */}
      <TimelineItem>
        <TimelineBackground>
          <BackgroundImage src={Image1} />
        </TimelineBackground>
        <TimelineYear>1987</TimelineYear>
        <TimelineContent>
          <Text>
            En aquel entonces, funcionaba una colonia de verano que recibía
            familias con sus niños y niñas.
          </Text>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineBackground>
          <BackgroundImage src={Image2} />
        </TimelineBackground>
        <TimelineYear>1988</TimelineYear>
        <TimelineContent>
          <Text>
            Il Pulcino Ballerino abrió sus puertas a pedido de la comunidad y se
            convirtió en muy poco tiempo en un Jardín de Infantes modelo en
            nuestro barrio.
          </Text>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineBackground>
          <BackgroundImage src={Image3} />
        </TimelineBackground>
        <TimelineYear>1992</TimelineYear>
        <TimelineContent>
          <Text>
            Con muchísimo esfuerzo y compromiso, comenzó nuestro nivel primario
          </Text>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineBackground>
          <BackgroundImage src={Image4} />
        </TimelineBackground>
        <TimelineYear>2000</TimelineYear>
        <TimelineContent>
          <Text>
            Nuestro nivel secundario recibió su primera promoción. Nuestra
            Historia, es sencilla, amorosa y llena de generosidad
          </Text>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineBackground>
          <BackgroundImage src={Image5} />
        </TimelineBackground>
        <TimelineYear>Hoy</TimelineYear>
        <TimelineContent>
          <Text>
            Después de tantos años, el objetivo sigue siendo el mismo.
          </Text>
          <Text>
            “Acompañar a nuestros estudiantes y a sus familias desde el afecto,
            la mirada y el profesionalismo”
          </Text>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export default TimelineContainer;

// const TitleContainer = styled.div`
//   position: absolute;
//   top: 128px;
//   left: 64px;
//   z-index: 2;
//   background-color: ${({ theme }) => theme.dark.color};
//   color: ${({ theme }) => theme.dark.contrast};
//   padding: 16px;
//   border: 2px dashed ${({ theme }) => theme.primary.color};

//   @media (max-width: 576px) {
//     top: 108px;
//     left: 50%;
//     width: 220px;
//     transform: translateX(-50%);
//     text-align: center;
//   }
// `;

// const Title = styled.h1`
//   color: white;
//   font-size: 1.6rem;

//   @media (max-width: 576px) {
//     font-size: 1.2rem;
//   }
// `;

const Text = styled.h5`
  color: white;
  font-size: 1.3rem;
  font-weight: normal;
  max-width: 300px;
  margin: auto;
  margin-top: 32px;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;

const TimelineBackground = styled.div`
  transform: translate3d(0, 0, 0);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
  transition: filter 0.5s ease;
  filter: grayscale(100%);
`;

const TimelineYear = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  font-size: 28px;
`;

const TimelineContent = styled.div`
  transform: translate3d(0, 0, 0) translateY(25px);
  position: relative;
  z-index: 1;
  text-align: center;
  margin: 0 1.618em;
  top: 60%;
  opacity: 0;

  h1 {
    font-family: "Pathway Gothic One", Helvetica Neue, Helvetica, Arial,
      sans-serif;
    text-transform: uppercase;
    color: #1779cf;
    font-size: 1.44rem;
    font-weight: normal;
  }
`;

const TimelineItem = styled.div`
  transform: translate3d(0, 0, 0);
  position: relative;
  width: 20%;
  height: calc(100vh - 60px);
  min-height: 600px;
  color: #fff;
  overflow: hidden;
  transition: width 0.5s ease;

  &:before,
  &:after {
    transform: translate3d(0, 0, 0);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &:after {
    background: rgba(10, 10, 10, 0.6);
    opacity: 1;
    transition: opacity 0.5s ease;
  }

  &:before {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 75%
    );
    z-index: 1;
    opacity: 0;
    transform: translate3d(0, 0, 0) translateY(50%);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  &:hover {
    width: 28% !important;

    &:after {
      opacity: 0;
    }

    &:before {
      opacity: 1;
      transform: translate3d(0, 0, 0) translateY(0);
      transition: opacity 1s ease, transform 1s ease 0.25s;
    }

    ${TimelineContent} {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.75s ease 0.5s;
    }

    ${TimelineBackground} {
      filter: grayscale(0);
    }

    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
`;

const Timeline = styled.div`
  min-height: calc(100vh - 60px);
  display: flex;
  background-color: #031625;

  &:hover {
    ${TimelineItem} {
      width: 18%;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    ${TimelineItem} {
      width: 100%;
    }

    &:hover {
      ${TimelineItem} {
        width: 100%;
      }
    }
  }
`;
