import { useCallback, useState, useEffect, useRef } from "react";
import Download from "../Download/Download";
import { DocumentContainerProps } from "./types";
import { Container, Documents, ArrowsContainer, StyledIcon } from "./styles";

const NUMBER_OF_DOCUMENTS = 5;
const CONTAINER_HEIGHT = 385;

const DocumentContainer = ({ files }: DocumentContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(0);

  const goDown = useCallback(() => {
    if ((currentPage + 1) * NUMBER_OF_DOCUMENTS < files?.length) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, files?.length]);

  const goUp = useCallback(() => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left: 0,
        top: currentPage * CONTAINER_HEIGHT,
        behavior: "smooth",
      });
    }
  }, [currentPage]);

  const renderFiles = useCallback(() => {
    return files?.map((file, index) => (
      <Download key={index} color="primary" docUrl={file.url} docName={file.label} />
    ));
  }, [files]);

  const renderArrows = useCallback(() => {
    if (files?.length <= NUMBER_OF_DOCUMENTS) return null;

    return (
      <ArrowsContainer>
        <StyledIcon
          disabled={currentPage === 0}
          size={28}
          icon="chevron-up"
          onClick={goUp}
        />
        <StyledIcon
          disabled={(currentPage + 1) * NUMBER_OF_DOCUMENTS >= files?.length}
          size={28}
          icon="chevron-down"
          onClick={goDown}
        />
      </ArrowsContainer>
    );
  }, [files, goUp, goDown, currentPage]);

  return (
    <Container height={CONTAINER_HEIGHT}>
      <Documents ref={containerRef}>{renderFiles()}</Documents>
      {renderArrows()}
    </Container>
  );
};

export default DocumentContainer;
