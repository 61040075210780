import { useEffect } from "react";
import { useParams } from "react-router";
import Image1 from "../../assets/images/_DUA5003.webp";
import Image2 from "../../assets/images/ingles_2.webp";
import Image3 from "../../assets/images/ingles_3.webp";
import PhotoAndText from "../../components/PhotoAndText/PhotoAndText";
import Header from "./Header/Header";

import { Section, ScrollSection } from "../../components";
import { useLevelController } from "../../hooks/useLevelController";
import { LevelsEnum } from "../../components/Admission/useForm";
import News from "../../components/News/News";
import Admission from "../../components/Admission/Admission";
import { NewsCategory } from "../../interfaces/news";

const English = () => {
  const { level } = useParams<{ level: string }>();
  const {
    scrollSectionRef,
    actualOption,
    news,
    onAnimationEnd,
    onSectionChanged,
    setActualOption,
  } = useLevelController("english-container", NewsCategory.English);

  useEffect(() => {
    if (level) {
      const sections = ["inicial", "primaria", "secundaria"];
      const section = sections.findIndex((s) => s === level);
      if (section >= 0 && scrollSectionRef.current) {
        scrollSectionRef.current.scroll(section + 1, false);
        setActualOption(section + 1);
      }
    }
  }, [level, scrollSectionRef, setActualOption]);

  return (
    <div className="english-container" id="english-container">
      <ScrollSection
        ref={scrollSectionRef}
        onSectionChanged={onSectionChanged}
        onAnimationEnd={onAnimationEnd}
      >
        <Section>
          <Header />
        </Section>
        <Section>
          <PhotoAndText
            id="nivel-inicial"
            title="Nivel Inicial"
            description={[
              "Estímulos en inglés desde Sala de 2: Trabajamos de forma lúdica, natural y gradual, a través de juegos, canciones, dramatizaciones, títeres, y cuentos.",
              "Una English Tutor junto a la maestra titular de la Sala, realizan actividades en otro idioma.",
              "Al finalizar el nivel inicial, los pequeños ya comprenden algunos vocablos y comandos básicos de la lengua. También habrán adquirido e incorporado el hábito del aprendizaje de la lengua extranjera de manera natural.",
            ]}
            imageUrl={Image1}
            isVisible={actualOption === 1}
            tags={["lúdica", "estímulos"]}
            lettersHighlighted={5}
          />
        </Section>
        <Section>
          <PhotoAndText
            id="nivel-primaria"
            isReverse
            dark
            title="Nivel Primario"
            description={[
              "Reconocidos por el Ministerio de Educación como Escuela con Intensificación de Idioma Inglés, teniendo 10 horas semanales de 1° a 7° grado y culminando con un Certificado Oficial.",
              "Enfocamos la enseñanza a ampliar el vocabulario y las estructuras desde un enfoque comunicativo combinándolo con la enseñanza sistemática de la gramática inglesa.",
              "Comenzamos desde muy temprano la preparación para rendir Exámenes Internacionales del idioma extranjero (U. of Cambridge), recibiendo así una certificación de prestigio y reconocimiento internacional.",
              "De 1º a 5º grado con la serie Young Learners (Starters, Movers y Flyers), y en 6º y 7º grado orientamos a rendir el Preliminary English Test (PET) for Schools.",
            ]}
            imageUrl={Image2}
            isVisible={actualOption === 2}
            tags={["certificación"]}
            lettersHighlighted={5}
          />
        </Section>
        <Section>
          <PhotoAndText
            id="nivel-secundaria"
            title="Nivel Medio"
            isVisible={actualOption === 3}
            lettersHighlighted={5}
            tags={["cae", "level B2"]}
            description={[
              "Los estudiantes se preparan para Certificar sus conocimientos en dos instancias: First for Schools (Level B2) en 3º año, y C1 Advanced (CAE) en 5º año. Si bien no es obligatorio rendir los examenes internacionales, en la Institución aspiramos a alcanzar el nivel más alto posible en la cursada.",
              "Asimismo, proyectamos con 4º y 5º año de la Escuela Media la preparación para participar en las Olimpíadas de Traducción que organiza anualmente la Universidad de Belgrano a través de su Programa para Escuelas Medias.",
            ]}
            imageUrl={Image3}
          />
        </Section>
        {news?.length > 0 ? (
          <Section>
            <News
              dark
              news={news}
              isVisible={actualOption === 4}
              section="ingles"
            />
          </Section>
        ) : null}
        <Section>
          <Admission
            level={LevelsEnum.ALL}
            isVisible={
              (actualOption === 5 && news?.length > 0) ||
              (actualOption === 4 && news?.length === 0)
            }
          />
        </Section>
      </ScrollSection>
    </div>
  );
};

export default English;
