import { MenuOptionEnum } from "../components/Navbar/types";

// export const API_URL = "https://backoffice-duayen.onrender.com";
export const API_URL = "https://institutoduayen.edu.ar/api";
export const INITIAL_LOADING_TIME = 700;
export const TRANSITION_TIME = 700;
export const NAVBAR_HEIGHT = 60;
export const BREAKPOINT_MD = 1200;

export const MENU_OPTIONS = [
  {
    id: MenuOptionEnum.AboutUs,
    label: "Nosotros",
  },
  {
    id: MenuOptionEnum.Kindergarten,
    label: "Inicial",
  },
  {
    id: MenuOptionEnum.Primary,
    label: "Primaria",
  },
  {
    id: MenuOptionEnum.Secondary,
    label: "Secundaria",
  },
  {
    id: MenuOptionEnum.English,
    label: "Inglés",
  },
  {
    id: MenuOptionEnum.News,
    label: "Noticias",
  },
  {
    id: MenuOptionEnum.Contact,
    label: "Contacto",
  },
  {
    id: MenuOptionEnum.Admission,
    label: "Inscripción",
  },
];
