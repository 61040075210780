import { createGlobalStyle } from "styled-components";
import {
  Theme,
  lightTheme as lightThemeDefault,
} from "@jcbruno96/react-ui-library";

export type ThemeColors =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "light"
  | "dark";

export const GlobalStyles = createGlobalStyle`

  * {
    font-family: ${({ theme }) => theme.font.paragraph}, sans-serif;
    margin: 0;
    box-sizing: border-box;

  }

  html, body {
    width: 100%;
    overflow-x: hidden;
  }

  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::-webkit-scrollbar {
      width: 8px;
    }
    
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }

  button {
    font-family: ${({ theme }) => theme.font.heading}, sans-serif;

    & > p {
      font-family: ${({ theme }) => theme.font.heading}, sans-serif;
      //letter-spacing: 1px;
    }
  }

  a {
    text-decoration: none;
    outline: none;
  }


  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }
`;

export const lightTheme: Theme = {
  ...lightThemeDefault,
  body: "#fff",
  text: "#121620",
  primary: {
    color: "#4CAF50",
    contrast: "#fff",
  },
  secondary: {
    //color: "#f9f9f9",
    color: "rgb(237, 240, 237)",
    contrast: "#000",
  },
  success: {
    color: "#4CAF50",
    contrast: "#fff",
  },
  danger: {
    color: "#f44336",
    contrast: "#fff",
  },
  light: {
    color: "#fff",
    contrast: "#2d2d2d",
  },
  dark: {
    color: "#2d2d2d",
    contrast: "#fff",
  },
  font: {
    heading: "Nunito",
    paragraph: "Nunito",
  },
  breakpoints: {
    xs: 576, //Mobile - portrait
    sm: 768, //Mobile - landscape
    md: 1200, //Tablet
    lg: 1440, //Desktop
    xl: 1920, //Desktop XL
  },
};

export const darkTheme: Theme = {
  ...lightTheme,
  body: "#1f2023",
  text: "#f5f5f5",
  secondary: {
    color: "#2f2f2f",
    contrast: "#fff",
  },
  dark: {
    color: "#252525",
    contrast: "#fff",
  },
  light: {
    color: "#2f2f2f",
    contrast: "#fff",
  },
};
