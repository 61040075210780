import moment from "moment";
import "moment/locale/es";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { News } from "../../interfaces/news";
import { actions } from "../../redux/loading";
import { INITIAL_LOADING_TIME } from "../../utils/constants";
import { delayMs } from "../../utils/delay";
import {
  NewsContainer,
  NewsContent,
  NewsDate,
  NewsDescription,
  NewsImage,
  NewsTitle,
  SeeMore,
} from "./styles";
import { NewsCardProps } from "./types";

const NewsCard = ({ news }: NewsCardProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onNewsClicked = useCallback(
    async (news: News) => {
      dispatch(actions.setLoading(true));
      await delayMs(INITIAL_LOADING_TIME + 100);
      history.push(`/noticias/detalle/${news.id}`);
    },
    [dispatch, history]
  );

  const imageUrl = useMemo(() => {
    return news && news?.news_files && news?.news_files?.length > 0
      ? news?.news_files[0].files?.url
      : "";
  }, [news]);

  return (
    <NewsContainer>
      <NewsImage src={imageUrl} alt="news-image" />
      <NewsContent>
        <NewsDate>
          {news?.date
            ? moment(news?.date, "YYYY-MM-DD")
                .locale("es")
                .format("DD MMMM, YYYY")
            : ""}
        </NewsDate>
        <NewsTitle>{news?.title}</NewsTitle>
        <NewsDescription>{news?.preview_text}</NewsDescription>
        <SeeMore onClick={() => onNewsClicked(news)}>Leer más</SeeMore>
      </NewsContent>
    </NewsContainer>
  );
};

export default NewsCard;
