import styled from "styled-components";
import { PageContainer } from "../../components/PageContainer/PageContainer";

export const StyledPageContainer = styled(PageContainer)`
  min-height: 100%;
  height: auto;
`;

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 16px;
  padding: 64px 10% 0px 10%;

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    justify-content: center;
  }
`;

export const Filter = styled.div<{ isActive: boolean }>`
  padding: 6px 16px;
  font-size: 20px;
  font-weight: 500;
  border: 2px solid;
  border-color: ${({ theme }) => theme.primary.color};
  color: #2d2d2d;
  transition: all 0.3s ease-in-out;
  border-radius: 32px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.primary.color : theme.light.color};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.primary.color};
    color: #fff;
  }
`;

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 60vh;
  gap: 32px;
`;

export const NoResultsText = styled.h3`
  margin: 0;
  color: #bbb;
  font-weight: 600;
  font-size: 28px;
  max-width: 360px;
  text-align: center;
`;

export const NoResultsImage = styled.img`
  width: 148px;
`;
