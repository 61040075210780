import { FileType } from "../../../components/DocumentContainer/types";

export const documents: FileType[] = [
  {
    url: "P_Actualizacion de datos.doc",
    label: "Ficha Actualización de datos",
  },
  { url: "P_Ficha Medica Anual 2023.doc", label: "Ficha Médica anual" },
  {
    url: "P_Solicitud de inscripcion pedagogica.doc",
    label: "Solicitud de inscripción pedagógica",
  },
  { url: "P_Documentacion ingreso.doc", label: "Documentación de ingreso" },
  { url: "P_Autorizacion campo.doc", label: "Autorización de campo" },
  {
    url: "P_Lista de Materiales 1 grado 2023.pdf",
    type: "pdf",
    label: "Lista de Materiales 1° grado",
  },
  {
    url: "P_Lista de Materiales 2 grado 2023.pdf",
    label: "Lista de Materiales 2° grado",
    type: "pdf",
  },
  {
    url: "P_Lista de Materiales 3 grado 2023.pdf",
    label: "Lista de Materiales 3° grado",
    type: "pdf",
  },
  {
    url: "P_Lista de Materiales 4 y 5 grado 2023.pdf",
    label: "Lista de Materiales 4° y 5° grado",
    type: "pdf",
  },
  {
    url: "P_Lista de Materiales 6 y 7 grado 2023.pdf",
    label: "Lista de Materiales 6° y 7° grado",
    type: "pdf",
  },
];
