import styled from "styled-components";
import { PageContainer } from "../PageContainer/PageContainer";
import Reveal from "../Reveal/Reveal";

export const NewsContainer = styled(PageContainer)<{ dark?: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ dark, theme }) =>
    dark ? theme.secondary.color : theme.light.color};
  color: ${({ theme }) => theme.light.contrast};
`;

export const ViewAll = styled.h6`
  font-size: 22px;
  margin: 0;
  margin-top: 24px;
  transition: all 0.3s ease-in-out;
  color: ${({ theme }) => theme.dark.color};
  cursor: pointer;
  width: 100%;
  text-align: center;
  font-weight: 700;

  &:hover {
    color: ${({ theme }) => theme.primary.color}
  }
`

export const StyledReveal = styled(Reveal)`
  height: 100%;
`