import { RevealProps } from "./types";
import { RevealContainer } from "./styles";

const Reveal = ({
  delay,
  offset = 100,
  children,
  className = "",
}: RevealProps) => {
  const classes = ["reveal", className].join(" ");
  return (
    <RevealContainer
      className={classes}
      delay={delay}
      offset={offset}
    >
      {children}
    </RevealContainer>
  );
};

export default Reveal;
